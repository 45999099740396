import React from 'react';
import ReactStars from "react-rating-stars-component";

import { BiChevronRight,BiMap } from 'react-icons/bi';
import { FaWhatsapp, FaStar } from "react-icons/fa";
import { FiPhone, FiHeart, FiShare2 } from "react-icons/fi";
import {FaHeart} from "react-icons/fa"
import { BsChatDots } from "react-icons/bs";
import { AiFillHeart,AiOutlineEye } from "react-icons/ai";
import axios from 'axios'

import { VscPreview } from 'react-icons/vsc'

import TopHeader from '../../Components/topHeader';
import Footer from '../../Components/footer/footer'

import BackGroundImge from "../../assets/imgs/img16.jpg";
import userImge from "../../assets/img/vendors/contact-info.jpg";
// slider images
import slider1 from "../../assets/img/vendors/water-165219_640.jpg";

// info imges
import infoImge1 from "../../assets/img/seats.svg";
import infoImge2 from "../../assets/img/music.svg";
import infoImge3 from "../../assets/img/byo.svg";
import infoImge4 from "../../assets/img/external.svg";

// Area imges
import areaImge1 from "../../assets/img/venue-icons/indoor_banquet.png";
import areaImge2 from "../../assets/img/venue-icons/outdoor_lawn.png";
import areaImge3 from "../../assets/img/venue-icons/poolside.png";
import areaImge4 from "../../assets/img/venue-icons/outdoor_terrace.png";

// Facilities imges
import FacImge1 from "../../assets/img/icons/details_icons/wifi.svg";
import FacImge2 from "../../assets/img/icons/details_icons/star.svg";
import FacImge3 from "../../assets/img/icons/details_icons/hanger.svg";
import FacImge4 from "../../assets/img/icons/details_icons/parking.svg";
import FacImge5 from "../../assets/img/icons/details_icons/projector.svg";
import FacImge6 from "../../assets/img/icons/details_icons/tv-monitor.svg";
import FacImge7 from "../../assets/img/icons/details_icons/close.svg";
import FacImge8 from "../../assets/img/icons/details_icons/close.svg";
import Experience from "../../assets/img/expert.svg"
import Payment from "../../assets/img/icons/details_icons/credit-card.svg";
import byo from "../../assets/img/byo.svg"
import External from "../../assets/img/external.svg"
// comment imges
import commentImge1 from "../../assets/img/vendors/contact-info.jpg";
import takeway from '../../assets/img/delivered.svg'
import truck from '../../assets/img/delivery-truck.svg'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ImageGroup, Image } from 'react-fullscreen-image'
import { Link, withRouter } from 'react-router-dom';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Card, Accordion,Spinner,Modal } from "react-bootstrap";
import { FacebookShareButton,WhatsappShareButton, FacebookIcon, WhatsappIcon } from "react-share";
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import {GrLocation} from 'react-icons/gr'


// Social Icons

import { FaFacebook } from 'react-icons/fa'
import { FaGooglePlus } from 'react-icons/fa'
import { IoLogoInstagram } from 'react-icons/io'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { CgWebsite } from 'react-icons/cg'

class venues_details extends React.Component {
  state = {
    listing: {},
    totalItem: "",
    token: "",
    edit: false,
    status: "",
    show: false,
    listData: null,
    loading: false,
    Photos: [],
    MenuPhotos:[],
    Price: [],
    loading: false,
    colapOpen: true,
    viewContact: false,
    city: "",
    login: false,
    ContactDetials: false,
    shareLink: false,
    star: null,
    description: "",
    reviesObject: [],
    tottalReviews: 0,
    avrageReviews: 0,

    totalReviews1: 0,
    totalReviews1Avg: 0,
    totalReviews2: 0,
    totalReviews2Avg: 0,
    totalReviews3: 0,
    totalReviews3Avg: 0,
    totalReviews4: 0,
    totalReviews4Avg: 0,
    totalReviews5: 0,
    totalReviews5Avg: 0,
    copied:false,
    star:0,
    description:"",
    reviewErr:false,
    resetRating:0,
    reviewSubmited:false,
    shortlist:false,
    Views:0,
    photoIndex: 0,
    isOpen: false,
    PhotosLink:[],
    photoIndexMenu: 0,
    isOpenMenu: false,
    PhotosLinkMenu:[]
  };
  getCity = (city) => {
    this.setState({
      city: city,
    });
  };
  shortList = async(a) => {
    let id=localStorage.getItem("user_id")
  this.setState({
    shortlist:a
  })
  let data={
    id:id,
    listingid:this.props.match.params.id
  }
  if(localStorage.getItem("user_token")){
    await axios.post(`${process.env.REACT_APP_API}/api/auth/addfavourite`,data).then((res)=>{
      if(res.data.Error==false){
       console.log("f")
      }
    })
  }else{
    this.props.history.push("/user-signin")
  }

  };

  submitReview = async (e) => {
    e.preventDefault();
    let star_reviews = this.state.star;
    let description = this.state.description;
    let listing_id = this.state.listing._id;
    let email = localStorage.getItem("user_email");
    let name = localStorage.getItem("user_name");
    let token = localStorage.getItem("user_token");
    if(!this.state.description || (star_reviews==0) ){
    this.setState({
      reviewErr:true
    })
    }else{
      await axios
      .post(
        `${process.env.REACT_APP_API}/api/auth/reviews`,
        { listing_id, email, name, star_reviews, description },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if(res.data.Error==false){
          this.setState({
            star: 0,
            description: "",
            reviewSubmited:true
          });
        }
      });
  };




  this.getReviews()
  

    }
getUserDetail=async()=>{
  let token = localStorage.getItem("user_token");
if(token){
  await axios
  .get(`${process.env.REACT_APP_API}/api/auth/getuserdetails`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then((res) => {
    if(res.data.Data){
      let list=res.data.Data.favouritelistings
      list.map((data)=>{
        if(data==this.props.match.params.id){
          this.setState({
            shortlist:true
          })
        }
      })
        
    }
   else if(res.data.token==false){
     localStorage.removeItem("user_token")
      this.props.history.push("/user-signin")
    }

  });
}


}
  getdetails = async () => {
    let token = localStorage.getItem("token");
    let name = localStorage.getItem("name");
    let { id } = this.props.match.params;
    await axios
      .get(`${process.env.REACT_APP_API}/api/auth/listing/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.Error == false) {
          this.setState({
            listing: res.data.data,
            Views:res.data.data.Views.length,
            token: token,
            Photos: res.data.data.Photos,
            MenuPhotos:res.data.data.Menu,
            Price: res.data.data.Price,
          });
          this.state.Photos.map((data)=>{
            this.setState({
              PhotosLink:[...this.state.PhotosLink,`${process.env.REACT_APP_API}/${data}`]
            })
          })
          if(this.state.MenuPhotos.length>0){
            this.state.MenuPhotos.map((data)=>{
              this.setState({
                PhotosLinkMenu:[...this.state.PhotosLinkMenu,`${process.env.REACT_APP_API}/${data}`]
              })
            })
          }

          this.setState({
            loading: true,
          });
        }
      });


  
  };



  rawMarkup(data) {
    var rawMarkup = data;
    return { __html: rawMarkup };
  }
  ViewContact=async ()=>{
    this.setState({ ContactDetials: true});
    let token=localStorage.getItem("user_token")
    let data={
      listing_id:this.state.listing._id
    }
    if(token){
      await axios.post(`${process.env.REACT_APP_API}/api/auth/addview`, data,{
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(res => {
      this.setState({
        Views:res.data.data.Views.length
      })
       
      })
    }

  }
  getReviews = async ()=>{
    this.setState({ resetRating :0});
    // let listing_id = "60a68e1fc7ecb616d8c00a68";
    let listing_id = this.props.match.params.id;
    // alert(listing_id);
     let token = localStorage.getItem("token");
    await axios
      .post(`${process.env.REACT_APP_API}/api/auth/customerreviews`,{listing_id}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // alert(JSON.stringify(res.data));
        // alert(JSON.stringify(res.data.length));
        if (res.data.Error == false) {
          this.setState({
            reviesObject: res.data.Data,
          });
        }else{
           this.setState({
             reviesObject: res.data.Data,
           });
        }
      }).catch(()=>{
        // alert("error")
      });
// alert(this.state.reviesObject.length);
    // geting revies data
    let totalReviews = this.state.reviesObject;
    // alert(JSON.stringify(totalReviews));
  let avrage =0;
    let totalRevieslength = totalReviews.length;
    var totalReviesCount = 0;
    totalReviews.map((data, index) => {
      totalReviesCount += data.star_reviews;
    });

    if (totalRevieslength != 0) {
      avrage = (totalReviesCount / totalRevieslength).toFixed(1);
    }
  

    // others

    var totalReviews1 = 0;
    var totalReviewslength1s = 0;
    totalReviews.map((data, index) => {
      if (data.star_reviews == 1) {
        totalReviews1 += data.star_reviews;
        totalReviewslength1s++;
      }
    });
    let totalReviewslength1 = (
      (totalReviewslength1s / totalRevieslength) *
      100
    ).toFixed(0);
    console.log(
      `at 1 = totalcount ${totalReviewslength1s} sum ${totalReviews1} and avrage is ${totalReviewslength1}`
    );

    var totalReviews2 = 0;
    var totalReviewslength2s = 0;
    totalReviews.map((data, index) => {
      if (data.star_reviews == 2) {
        totalReviews2 += data.star_reviews;
        totalReviewslength2s++;
      }
    });

    let totalReviewslength2 = (
      (totalReviewslength2s / totalRevieslength) *
      100
    ).toFixed(0);

    console.log(
      `at 2 = totalcount ${totalReviewslength2s} sum ${totalReviews2} and avrage is ${totalReviewslength2}`
    );

    var totalReviews3 = 0;
    var totalReviewslength3s = 0;
    totalReviews.map((data, index) => {
      if (data.star_reviews == 3) {
        totalReviews3 += data.star_reviews;
        totalReviewslength3s++;
      }
    });
    let totalReviewslength3 = (
      (totalReviewslength3s / totalRevieslength) *
      100
    ).toFixed(0);

    console.log(
      `at 3 = totalcount ${totalReviewslength3s} sum ${totalReviews3} and avrage is ${totalReviewslength3}`
    );

    var totalReviews4 = 0;
    var totalReviewslength4s = 0;
    totalReviews.map((data, index) => {
      if (data.star_reviews == 4) {
        totalReviews4 += data.star_reviews;
        totalReviewslength4s++;
      }
    });
    let totalReviewslength4 = (
      (totalReviewslength4s / totalRevieslength) *
      100
    ).toFixed(0);
    console.log(
      `at 4 = totalcount ${totalReviewslength4s} sum ${totalReviews4} and avrage is ${totalReviewslength4}`
    );

    var totalReviews5 = 0;
    var totalReviewslength5s = 0;
    totalReviews.map((data, index) => {
      if (data.star_reviews == 5) {
        totalReviews5 += data.star_reviews;
        totalReviewslength5s++;
      }
    });
    let totalReviewslength5 = (
      (totalReviewslength5s / totalRevieslength) *
      100
    ).toFixed(0);

    console.log(
      `at 5 = totalcount ${totalReviewslength5s} sum ${totalReviews5} and avrage is ${totalReviewslength5}`
    );


    this.setState({
      resetRating: 1,
      tottalReviews: totalRevieslength,
      avrageReviews: avrage,

      totalReviews1: totalReviewslength1s,
      totalReviews1Avg: totalReviewslength1,
      totalReviews2: totalReviewslength2s,
      totalReviews2Avg: totalReviewslength2,
      totalReviews3: totalReviewslength3s,
      totalReviews3Avg: totalReviewslength3,
      totalReviews4: totalReviewslength4s,
      totalReviews4Avg: totalReviewslength4,
      totalReviews5: totalReviewslength5s,
      totalReviews5Avg: totalReviewslength5,
    });


   
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    document.body.classList.remove('hidden-sidebar')
    let city = localStorage.getItem("city");
    this.setState({
      city: city,
    });
    this.checkLogin();
    this.getdetails();
    this.getReviews();
    this.getUserDetail()

 
    
  }
  checkLogin = () => {
    let user = localStorage.getItem("user_token");
    if (user) {
      this.setState({
        login: true,
      });
    }
  };
  logout = () => {
    this.setState({
      login: false,
      likeicon: false,
    });
  };
  getMemberRegesterDetails = (e) => {
    var d = new Date(e);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var year = d.getFullYear();
    var Month = months[d.getMonth()];
    var Day = d.getDate();

    return Month + "-" + year;
  };

  dateReviewsFormater(date) {
    var date = new Date(date);
    var year = date.getFullYear();
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var Month = months[date.getMonth()];
    var Day = date.getDate();
    // date ends
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var dateAndTime =
      hours + ":" + minutes + " " + ampm + " " + Month + "/" + Day + "/" + year;
    return dateAndTime;
  }
ImageModal=(i)=>{
  document.body.classList.add('hidden-sidebar')
  this.setState({ isOpen: true,photoIndex:i })
}
ImageModalMenu=(i)=>{
  console.log("done")
  document.body.classList.add('hidden-sidebar')
  this.setState({ isOpenMenu: true,photoIndexMenu:i })
}
  // filtering reviews sapreater

  render() {
    console.log("faisal",this.state.PhotosLinkMenu)
    const { photoIndex, isOpen,photoIndexMenu,isOpenMenu } = this.state;
    return (
      <>
        <TopHeader
          getCity={(a) => this.getCity(a)}
          city={this.state.city}
          logout={this.logout}
          login={this.state.login}
        />



        {this.state.loading ? (
          <>
            {/* <div
              className="sub-banner"
              style={{ backgroundImage: `url(${BackGroundImge})` }}
            >
              <div className="container">
                <div className="breadcrumb-area text-white">
                  <h1>{this.state.listing.Name}</h1>
                  <ul className="breadcrumbs">
                    <li>Home</li>
                    <li>
                      <BiChevronRight />
                    </li>
                    <li>{this.state.listing.Name}</li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* ******************************** */}
            <div className="partycrack-details-page content-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    {/* ********************** */}
                    <div className="vanue_details_carasole">
                      <Carousel  showArrows={true}>
                        {this.state.Photos.map((data, i) => {
                          return (
                            <div onClick={()=>{this.ImageModal(i)}}>
                              <img                             
                                src={`${process.env.REACT_APP_API}/${data}`}
                              />
                            </div>
                          );
                        })}
                      </Carousel>
                      {/*                   <a className="whybtn  px-3" href="javascript:void(0);">
                    See All Photos
                      </a> */}
                    </div>
                    {/* ********************** */}
                  </div>
                  

                  <div className="col-lg-4 col-md-12">
                  <div  className="d-lg-none d-block">
                    <h2 style={{margin:"0 0 20px",fontSize: "16px",fontWeight: "500"}} ><GrLocation /> {this.state.listing.Address.Address} </h2>
                </div>
                    <div className="sidebar-right">
                      <div className="tg-sellercontactdetail mb-4">
                        <div className="tg-sellertitle">
                          <div className="main_align_txt">
                            <h1>{this.state.listing.Name}</h1>
                            <p><AiOutlineEye /> {this.state.Views} </p>
                          </div> 
                          <p style={{margin: "0px" ,color: "#666"}}>{this.state.listing.Tagline}</p>
                        </div>

                        <div className="tg-sellercontact">
                          <div className="tg-memberinfobox">
                            <div className="tg-memberinfo">
                              <h3>
                                <a href="javascript:void(0);">
                                  {this.state.listing.vendor_id.name}
                                </a>
                              </h3>
                              <span>
                              {" "}
                                Member Since {this.getMemberRegesterDetails(
                                  this.state.listing.vendor_id.register_date
                                )}
                              </span>
                            </div>
                          </div>
                          <div style={{ marginBottom: "14px" }} className="">
                            <div className="tg-memberinfobox">
                              <Accordion defaultActiveKey="0">
                                <Card>
                                  <Card.Header>
                                    <Accordion.Toggle
                                      onClick={() => {
                                        this.setState({
                                          colapOpen: !this.state.colapOpen,
                                        });
                                      }}
                                      className="data"
                                      as={Button}
                                      variant="button"
                                      eventKey="0"
                                    >
                                      <div> Price Info</div>
                                      <div>
                                        {this.state.colapOpen ? (
                                          <i
                                            style={{ marginLeft: "10px" }}
                                            className="fa fa-chevron-down"
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ marginLeft: "10px" }}
                                            className="fa fa-chevron-up"
                                          ></i>
                                        )}
                                      </div>
                                    </Accordion.Toggle>
                                  </Card.Header>
                                  <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                      <div>
                                        {this.state.Price.map((data) => {
                                          return (
                                            <div className="PerPersonPrice">
                                              <div>${data.Value}</div>
                                              <div>{data.Option}</div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            </div>
                          </div>
                          <div
                            className="d-flex flex-wrap justify-content-center vendor-button"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Click here for send message"
                          >
                            {this.state.listing.Accept_Whatsapp ?
                             <a className="tg-btnphone whatsapp mb-3"
                             href={`https://wa.me/+61${this.state.listing.Mobile_Number.substring(1)}`}
                             target="_blank"
                             rel="noopener noreferrer">
                               
                               <FaWhatsapp />
                               <em>Send Message</em>
                             </a>
                             : 
                             ""
                             }
                           
                            <a
                              onClick={
                                this.ViewContact
                              }
                              className="tg-btnphone contact mb-3"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Click here for contact no."
                              style={{cursor:"pointer"}}
                            >
                              <FiPhone />
                              <em>View Contact</em>
                            </a>

                            <a
                              className="tg-btnphone enquire mb-3 "
                              data-toggle="collapse"
                              href="#collapseContact"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseContact"
                              onClick={() => {
                                this.setState({
                                  viewContact: !this.state.viewContact,
                                });
                              }}
                            >
                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Click here for Enquire Now"
                                className="text-center "

                              >
                                <BsChatDots />

                                <em>Enquire Now</em>
                              </span>
                            </a>
                          </div>
                          <div
                            className={
                              this.state.viewContact
                                ? "tg-sellercontactdetail mb-4"
                                : "d-none"
                            }
                          >
                            <div className="tg-sellertitle">
                              <h1>Vendors Contact</h1>
                            </div>
                            <div className="tg-sellercontact">
                              <form className="from">
                                <div className="form-group name">
                                  <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    placeholder="Name*"
                                  />
                                </div>
                                <div className="form-group number">
                                  <input
                                    type="text"
                                    name="phone"
                                    className="form-control"
                                    placeholder="Number"
                                  />
                                </div>
                                <div className="form-group number">
                                  <input
                                    type="email"
                                    name="Email"
                                    className="form-control"
                                    placeholder="Email*"
                                  />
                                </div>
                                <div className="form-group message">
                                  <textarea
                                    className="form-control"
                                    name="message"
                                    placeholder="Write message*"
                                  ></textarea>
                                </div>
                                <div className="send-btn text-center">
                                  <button type="submit" className="whybtn">
                                    Send Message
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap justify-content-center new-options">
                            {/*                         <a href="" className="rating-option text-center">
                          <span className="StarRating center rating-5 regular d-flex justify-content-center align-items-center">
                            <FaStar /> <span>4.8</span>
                          </span>
                          <small>19 reviews</small>
                        </a> */}

                              <a className="shortlist d-flex justify-content-center align-items-center flex-column text-center">
                               <VscPreview />
                                <small>{this.state.reviesObject.length>0 ? this.state.reviesObject.length==1?`${this.state.reviesObject.length} Review`:`${this.state.reviesObject.length} Reviews`: "0 Review" } </small>
                              </a>
                       
                            <a
                              className="shortlist d-flex justify-content-center align-items-center flex-column text-center"
                            >
                              {
                                this.state.shortlist ? 
                                <span  
                                onClick={(a)=>this.shortList(false)}
                                style={{cursor:"pointer"}}
                                >
                                <FaHeart />
                              </span>
                                :
                                <span
                                style={{cursor:"pointer"}}
                                onClick={(a)=>this.shortList(true)}
                                >
                                <FiHeart />
                              </span>
                              }
                              
                              
 
                              <small>Shortlist</small>
                            </a>

                              

                            <a
                              href="#0"
                              onClick={() => {
                                this.setState({ shareLink: true });
                              }}
                              className="share text-center d-flex justify-content-center align-items-center flex-column"
                            >
                              <span>
                                <FiShare2 />
                              </span>
                              <small>Share</small>
                            </a>
                          </div>
                          <div className="social_icons">
                            {this.state.listing.Social_Media_Links.facebook ? <a target="_blank" href={this.state.listing.Social_Media_Links.facebook}> <FaFacebook /> </a>: ""}
                            {this.state.listing.Social_Media_Links.google ? <a target="_blank" href={this.state.listing.Social_Media_Links.google}> <FaGooglePlus /> </a>:""}
                            {this.state.listing.Social_Media_Links.instagram ?<a target="_blank" href={this.state.listing.Social_Media_Links.instagram}> <IoLogoInstagram /> </a>:"" }
                            {this.state.listing.Social_Media_Links.twitter ?<a target="_blank" href={this.state.listing.Social_Media_Links.twitter}> <AiFillTwitterCircle /> </a>:"" }
                            {this.state.listing.Social_Media_Links.website ?<a target="_blank"  href={this.state.listing.Social_Media_Links.website}> <CgWebsite /> </a>:""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ********************** */}

                {/* ********************** */}

                <div  className="d-lg-block d-none">
                    <h2 style={{margin:"0 0 20px",fontSize: "16px",fontWeight: "500"}} ><GrLocation /> {this.state.listing.Address.Address} </h2>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="partycrack-key-information mb-40 box_main">
                      {/* <h3 className="heading-2">Experience & Payment</h3> */}
                      <div className="row form-row">
                        <div className="col-md-3 col-lg-2 col-6">
                          <div className="key-point-inner">
                            <div className="img-wrap">
                            <h3 className="heading-2">Experience</h3>
                              <img src={Experience} alt="" />
                            </div>
                            <div className="text-wrap">
                              <h4>{this.state.listing.Industry_Experience} years</h4>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 col-lg-2 col-6">
                          <div className="key-point-inner">
                            <div className="img-wrap">
                            <h3 className="heading-2">Payment Terms</h3>
                              <img src={Payment} alt="" />
                            </div>
                            <div className="text-wrap">
                              <h4>
                                {this.state.listing.Payment_Terms.Option}{" "}
                                {this.state.listing.Payment_Terms.Percentage}
                              </h4>
                            </div>
                          </div>
                        </div>
                        {this.state.listing.Service.Options.Takeaway_Only ?
                           <div className="col-md-3 col-lg-2 col-6">
                          <div className="key-point-inner">
                            <div className="img-wrap">
                            <h3 className="heading-2">Takeaway only</h3>
                              <img src={takeway} alt="takeaway" />
                            </div>
                          </div>
                        </div>
                         : 
                         ""}
                        {this.state.listing.Service.Options.Food_Vehicle ?

                        <div className="col-md-3 col-lg-2 col-6">
                          <div className="key-point-inner">
                            <div className="img-wrap">
                            <h3 className="heading-2">Food Truck/Car/Van</h3>
                              <img src={truck} alt="truck" />
                            </div>
                          </div>
                        </div>
                        :
                        ""
                               }
                      </div>
                    </div>

                    <div className="partycrack-description mb-40 box_main">
                      <h3 className="heading-2">Description</h3>
                      <p
                        dangerouslySetInnerHTML={this.rawMarkup(
                          this.state.listing.Description
                        )}
                      ></p>
                    </div>
                    {(this.state.listing.Inclusion.length>0 && this.state.listing.Exclusion.length>0) ?
                                        <div className="partycrack-description mb-40 box_main">
                                        <h3 className="heading-2">Services</h3>
                                        <div className="row">
                                          <div className="col-md-6 add_brdr">
                                            <div className="head_ser">
                                              <h4>Inclusions</h4>
                                            </div>
                                            <div className="listing_serv">
                                              <ul>
                                                {this.state.listing.Inclusion.map((data) => {
                                                  return <li>{data}</li>;
                                                })}
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="head_ser">
                                              <h4>Exclusions</h4>
                                            </div>
                                            <div className="listing_serv">
                                              <ul>
                                                {this.state.listing.Exclusion.map((data) => {
                                                  return <li>{data}</li>;
                                                })}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                           : 
                                        ""}
                    {this.state.MenuPhotos.length>0 ?
                    <div className="col-lg-12 col-md-12">
                    <div className="vanue_details_carasole">
                                    <div className="head_ser">
                                              <h4>Images of Menu</h4>
                                            </div>
                      <Carousel showArrows={true}>
                        {this.state.MenuPhotos.length>0 && this.state.MenuPhotos.map((data, i) => {
                          return (
                            <div 
                            onClick={()=>{this.ImageModalMenu(i)}}
                            >
                              <img
                                src={`${process.env.REACT_APP_API}/${data}`}

                              />
                            </div>
                          );
                        })}
                      </Carousel>
                    
                    </div>
                  </div>
         :
        ""}
                
                    <div className="partycrack-description mb-40 box_main">
                      <h3 className="heading-2">Event Details</h3>
                      <div className="row">
                        <div className="col-md-6 add_brdr">
                          <div className="head_ser">
                            <h4>Types of event</h4>
                          </div>
                          <div className="listing_serv">
                            <ul>
                              {this.state.listing.Event_Types.map((data) => {
                                return <li>{data}</li>;
                              })}
                            </ul>
                          </div>
                        </div>
                        {this.state.listing.Minimum_Group_Size ?
                         <div className="col-md-6 add_brdr">
                           <div className="head_ser">
                            <h4>Minimum group size</h4>
                              </div>
                            <div style={{paddingTop: "10px",borderTop: "1px solid #efefef"}} className="listing_serv">
                              <p>{this.state.listing.Minimum_Group_Size}</p>
                                  </div>
                                        </div>
                          :
                          ""}

                      </div>
                    </div>
                     {(this.state.listing.Sub_Category=="Catering Services"&& this.state.listing.Cusisine.length>0) ?
                                         <div className="partycrack-description mb-40 box_main">
                                         <h3 className="heading-2">Cusisine</h3>
                                         <div className="row">
                                           <div className="col-md-6 add_brdr">
                                             <div className="listing_serv">
                                               <ul>
                                               {this.state.listing.Cusisine.map((data) => {
                                                   return <li>{data}</li>
                                                 })}
                                               
                                               </ul>
                                             </div>
                                           </div>
                   
                                         </div>
                                       </div>
                      : 
                      ""
                      }
                                          {this.state.listing.Category=="Food" ?
                                         <div className="partycrack-description mb-40 box_main">
                                         <h3 className="heading-2">Catering</h3>
                                         <div className="row">
                                           <div className="col-md-6 add_brdr">
                                             <div className="listing_serv">
                                               <ul>
                                              {this.state.listing.Catering.Halal_Menu ? <li>Hala Menue </li> : ""}  
                                              {this.state.listing.Catering.Kosher_Menu ? <li>Kosher Menu </li> : ""}   
                                              {this.state.listing.Catering.Vegan_Menu ? <li>Vegan Menu </li> : ""}   
                                              {this.state.listing.Catering.Vegetarian_Menu ? <li>Vegetarian Menu </li> : ""}   
                                             {this.state.listing.Catering.Gluten_Free_Menu ? <li>Gluten Free Menu </li> : ""}
                                               
                                               </ul>
                                             </div>
                                           </div>
                   
                                         </div>
                                       </div>
                      : 
                      ""
                      }

                    {this.state.listing.Cancellation_Policy ?
                   <div className="partycrack-description mb-40 box_main">
                      <h3 className="heading-2">Cancellation Policy</h3>
                        <p
                          dangerouslySetInnerHTML={this.rawMarkup(
                          this.state.listing.Cancellation_Policy
                            )}
                            ></p>
                            </div>
                  :
                  ""  
                  }

                    <div className="partycrack-description mb-40 box_main">
                      <h3 className="heading-2">Availability</h3>
                      <div class="row">
                        <div className="col-12">
                          {this.state.listing.Availability.Sunday.Availability ?
                              <div className="timeTableWeek">
                                 <div>Sunday :</div>
                                  <div>
                                    {this.state.listing.Availability.Sunday.From
                                    ? this.state.listing.Availability.Sunday.From
                                     : "---"}
                                        </div>
                                        <div>To</div>
                                         <div>
                                         {this.state.listing.Availability.Sunday.To
                                         ? this.state.listing.Availability.Sunday.To
                                          : "---"}
                                         </div>
                                       </div>

                          :
                          ""
                          }
                         {this.state.listing.Availability.Monday.Availability ?

                          <div className="timeTableWeek">
                            <div>Monday :</div>
                            <div>
                              {this.state.listing.Availability.Monday.From
                                ? this.state.listing.Availability.Monday.From
                                : "---"}
                            </div>
                            <div>To</div>
                            <div>
                              {this.state.listing.Availability.Monday.To
                                ? this.state.listing.Availability.Monday.To
                                : "---"}
                            </div>
                          </div>
                          :
                          ""
                           }
               {this.state.listing.Availability.Tuesday.Availability ?

                          <div className="timeTableWeek">
                            <div>Tuesday :</div>
                            <div>
                              {this.state.listing.Availability.Tuesday.From
                                ? this.state.listing.Availability.Tuesday.From
                                : "---"}
                            </div>
                            <div>To</div>
                            <div>
                              {" "}
                              {this.state.listing.Availability.Tuesday.To
                                ? this.state.listing.Availability.Tuesday.To
                                : "---"}
                            </div>
                          </div>
                          :
                          ""
                           }
                        {this.state.listing.Availability.Wednesday.Availability ?

                          <div className="timeTableWeek">
                            <div>Wednesday :</div>
                            <div>
                              {this.state.listing.Availability.Wednesday.From
                                ? this.state.listing.Availability.Wednesday.From
                                : "---"}{" "}
                            </div>
                            <div>To</div>
                            <div>
                              {" "}
                              {this.state.listing.Availability.Wednesday.To
                                ? this.state.listing.Availability.Wednesday.To
                                : "---"}
                            </div>
                          </div>
                          :
                          ""
                             }
                           {this.state.listing.Availability.Thursday.Availability ?  
                          <div className="timeTableWeek">
                            <div>Thursday :</div>
                            <div>
                              {" "}
                              {this.state.listing.Availability.Thursday.From
                                ? this.state.listing.Availability.Thursday.From
                                : "---"}
                            </div>
                            <div>To</div>
                            <div>
                              {" "}
                              {this.state.listing.Availability.Thursday.To
                                ? this.state.listing.Availability.Thursday.To
                                : "---"}{" "}
                            </div>
                          </div>
                          :
                          ""
                            }
                            {this.state.listing.Availability.Friday.Availability ?  
                          <div className="timeTableWeek">
                            <div>Friday :</div>
                            <div>
                              {this.state.listing.Availability.Friday.From
                                ? this.state.listing.Availability.Friday.From
                                : "--"}{" "}
                            </div>
                            <div>To</div>
                            <div>
                              {" "}
                              {this.state.listing.Availability.Friday.To
                                ? this.state.listing.Availability.Friday.To
                                : "---"}
                            </div>
                          </div>
                          :
                          ""
                             }
                           {this.state.listing.Availability.Saturday.Availability ?    
                          <div className="timeTableWeek">
                            <div>Saturday :</div>
                            <div>
                              {" "}
                              {this.state.listing.Availability.Saturday.From
                                ? this.state.listing.Availability.Saturday.From
                                : "---"}{" "}
                            </div>
                            <div>To</div>
                            <div>
                              {" "}
                              {this.state.listing.Availability.Saturday.To
                                ? this.state.listing.Availability.Saturday.To
                                : "---"}{" "}
                            </div>
                          </div>
                          :
                          ""
                            }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ********************** */}

                {/* ********************** */}

                {/* ********************** */}

                {/* ********************** */}
                <div className="row">
                  <div className="col-12">
                    <div className="music-equipment plans-list mb-40 box_main">
                      <h3 className="heading-2">Property Location</h3>
                      <div className="row">
                        <div className="col-12">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27442.456486498657!2d76.704336!3d30.709767!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feef67a401d1b%3A0x60cd582ea8bf9d40!2sPhase%208%2C%20Industrial%20Area%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1620210374104!5m2!1sen!2sin"
                            width="100%"
                            height="400"
                            style={{ border: "none" }}
                            allowfullscreen=""
                            loading="lazy"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ********************** */}
                <div className="reivews mb-40 box_main">
                  <div className={!this.state.reviewSubmited ? "d-none" : null}>
                    <h5 className="text-success border-bottom pb-3">Reivews Submited</h5>
                  </div>
                  <div
                    className={`${this.state.reviewSubmited ? 'd-none' :null} ${
                      localStorage.getItem("user_token") === null
                        ? "d-none"
                        : "contact-2 ca mb-40"
                    }`}
                  >
                    <h3 className="heading">Review Venue</h3>
                    <ReactStars
                      count={5}
                      onChange={(a) => this.setState({ star: a })}
                      size={24}
                      activeColor="#f2b600"
                      isHalf={false}
                      rating={this.state.star}
                    />
                    <div className="row">
                      <div className="form-group message col-md-12">
                        <textarea
                          className="form-control"
                          name="message"
                          placeholder="Write about your experience"
                          onChange={(e) => {
                            this.setState({ description: e.target.value });
                          }}
                          value={this.state.description}
                        ></textarea>
                        {this.state.reviewErr ? (
                          <p style={{ color: "red" }}>
                            Please Enter Description and Select Stars to Rate it 
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="send-btn col-md-12">
                        <button
                          onClick={this.submitReview}
                          type="submit"
                          className="whybtn"
                        >
                          Submit Reviews
                        </button>
                      </div>
                    </div>
                  </div>
                  <h3 className="heading-2">Reivews</h3>

                  <div className="row rating-area mb-20">
                    <div className="col-lg-4 col-md-4">
                      <div className="average-rating mb-30">
                        <h1>{this.state.avrageReviews}</h1>
                        <div className="ratings d-flex justify-content-center mb-10">
                          {this.state.resetRating && (
                            <ReactStars
                              count={5}
                              value={this.state.avrageReviews}
                              edit={false}
                              size={24}
                              activeColor="#f2b600"
                              isHalf={true}
                            />
                          )}
                        </div>
                        <h4>{this.state.tottalReviews} Review</h4>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8">
                      <div className="rating-details mb-30">
                        <div className="rating-item">
                          <div className="ratings">
                            <ReactStars
                              count={5}
                              value={5}
                              edit={false}
                              size={24}
                              activeColor="#f2b600"
                              isHalf={true}
                            />
                          </div>
                          <div className="rating-bar">
                            <div
                              className="rating-width"
                              style={{
                                width: `${this.state.totalReviews5Avg}%`,
                              }}
                            ></div>
                          </div>
                          <div className="rating-count">
                            <span>({this.state.totalReviews5})</span>
                          </div>
                        </div>
                        <div className="rating-item">
                          <div className="ratings">
                            <ReactStars
                              count={5}
                              value={4}
                              edit={false}
                              size={24}
                              activeColor="#f2b600"
                              isHalf={true}
                            />
                          </div>
                          <div className="rating-bar">
                            <div
                              className="rating-width"
                              style={{
                                width: `${this.state.totalReviews4Avg}%`,
                              }}
                            ></div>
                          </div>
                          <div className="rating-count">
                            <span>({this.state.totalReviews4})</span>
                          </div>
                        </div>
                        <div className="rating-item">
                          <div className="ratings">
                            <ReactStars
                              count={5}
                              value={3}
                              edit={false}
                              size={24}
                              activeColor="#f2b600"
                              isHalf={true}
                            />
                          </div>
                          <div className="rating-bar">
                            <div
                              className="rating-width"
                              style={{
                                width: `${this.state.totalReviews3Avg}%`,
                              }}
                            ></div>
                          </div>
                          <div className="rating-count">
                            <span>({this.state.totalReviews3})</span>
                          </div>
                        </div>
                        <div className="rating-item">
                          <div className="ratings">
                            <ReactStars
                              count={5}
                              value={2}
                              edit={false}
                              size={24}
                              activeColor="#f2b600"
                              isHalf={true}
                            />
                          </div>
                          <div className="rating-bar">
                            <div
                              className="rating-width"
                              style={{
                                width: `${this.state.totalReviews2Avg}%`,
                              }}
                            ></div>
                          </div>
                          <div className="rating-count">
                            <span>({this.state.totalReviews2})</span>
                          </div>
                        </div>
                        <div className="rating-item">
                          <div className="ratings">
                            <ReactStars
                              count={5}
                              value={1}
                              edit={false}
                              size={24}
                              activeColor="#f2b600"
                              isHalf={true}
                            />
                          </div>
                          <div className="rating-bar">
                            <div
                              className="rating-width"
                              style={{
                                width: `${this.state.totalReviews1Avg}%`,
                              }}
                            ></div>
                          </div>
                          <div className="rating-count">
                            <span>({this.state.totalReviews1})</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="comments vanues">
                    {/* <pre>{JSON.stringify(totalReviews, null, 2)}</pre> */}
                    {this.state.reviesObject &&
                      this.state.reviesObject.map((data, index) => {
                        return (
                          <li>
                            <div className="comment">
                              <div className="comment-author">
                                <a href="#">
                                  <img src={commentImge1} alt="comments-user" />
                                </a>
                              </div>
                              <div className="comment-content">
                                <div className="comment-meta">
                                  <h3>{data.name}</h3>
                                  <div className="comment-meta">
                                    {this.dateReviewsFormater(data.date)}
                                  </div>
                                </div>
                                <p>{data.description}</p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                {/* ********************** */}
                {/* <div className="row similar-partycrack simelerVanes">
                  <div className="col-12">
                    <h3 className="heading-2">Similar Venues</h3>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="partycrack-box">
                      <div className="partycrack-thumbnail">
                        <a href="#0" className="partycrack-img">
                          <img
                            className="d-block w-100"
                            src={slider1}
                            alt="partycrack"
                          />
                        </a>
                        <a className="favourites" href="#">
                          <FiHeart />
                        </a>
                      </div>
                      <div className="content_vendor">
                        <div className="d-md-flex justify-content-between">
                          <div className="detail">
                            <h1 className="title">
                              <a href="#0">Calista Resort</a>
                            </h1>
                            <div className="location">
                              <a href="#0">
                                <i className="flaticon-pin"></i>123 Kathal St.
                                Tampa City
                              </a>
                            </div>
                          </div>
                          <div className="reat_reviews">
                            <span className="StarRating center rating-5 regular d-flex justify-content-center align-items-center">
                              <i className="fa fa-star margin-r-5"></i>4.8
                            </span>
                            <small>19 reviews</small>
                          </div>
                        </div>
                        <div className="footer d-md-flex justify-content-between align-items-center">
                          <span className="strike ">
                            <i className="far fa-dollar-sign"></i> 1,000 Per
                            Plate
                          </span>
                          <a
                            href="#0"
                            className="whatsapp-contact d-flex justify-content-center align-items-center"
                          >
                            <FaWhatsapp />
                            Contact
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="partycrack-box">
                      <div className="partycrack-thumbnail">
                        <a href="#0" className="partycrack-img">
                          <img
                            className="d-block w-100"
                            src={BackGroundImge}
                            alt="partycrack"
                          />
                        </a>
                        <a className="favourites active" href="#">
                          <AiFillHeart />
                        </a>
                      </div>
                      <div className="content_vendor">
                        <div className="d-md-flex justify-content-between">
                          <div className="detail">
                            <h1 className="title">
                              <a href="#0">The Wedding Ties</a>
                            </h1>
                            <div className="location">
                              <a href="#0">
                                <i className="flaticon-pin"></i>123 Kathal St.
                                Tampa City
                              </a>
                            </div>
                          </div>
                          <div className="reat_reviews">
                            <span className="StarRating center rating-5 regular d-flex justify-content-center align-items-center">
                              <i className="fa fa-star margin-r-5"></i>4.8
                            </span>
                            <small>19 reviews</small>
                          </div>
                        </div>
                        <div className="footer d-md-flex justify-content-between align-items-center">
                          <span className="strike ">
                            <i className="far fa-dollar-sign"></i> 1,000 Per
                            Plate
                          </span>
                          <a
                            href="#0"
                            className="whatsapp-contact d-flex justify-content-center align-items-center"
                          >
                            <FaWhatsapp /> Contact
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="partycrack-box">
                      <div className="partycrack-thumbnail">
                        <a href="#0" className="partycrack-img">
                          <img
                            className="d-block w-100"
                            src={slider1}
                            alt="partycrack"
                          />
                        </a>
                        <a className="favourites active" href="#">
                          <AiFillHeart />
                        </a>
                      </div>
                      <div className="content_vendor">
                        <div className="d-md-flex justify-content-between">
                          <div className="detail">
                            <h1 className="title">
                              <a href="#0">Calista Resort</a>
                            </h1>
                            <div className="location">
                              <a href="#0">
                                <i className="flaticon-pin"></i>123 Kathal St.
                                Tampa City
                              </a>
                            </div>
                          </div>
                          <div className="reat_reviews">
                            <span className="StarRating center rating-5 regular d-flex justify-content-center align-items-center">
                              <i className="fa fa-star margin-r-5"></i>4.8
                            </span>
                            <small>19 reviews</small>
                          </div>
                        </div>
                        <div className="footer d-md-flex justify-content-between align-items-center">
                          <span className="strike ">
                            <i className="far fa-dollar-sign"></i> 1,000 Per
                            Plate
                          </span>
                          <a
                             href="https://wa.me/+61477777074"
                             class="whatsapp-contact d-flex justify-content-center align-items-center"
                             target="_blank"
                             rel="noopener noreferrer"
                          >
                            <FaWhatsapp /> Contact
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* ********************** */}
              </div>
            </div>
            {/* ******************************** */}

            <Modal
              show={this.state.ContactDetials}
              centered
              onHide={() => this.setState({ ContactDetials: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title>Contact Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <b>Mobile Number:</b> {this.state.listing.Mobile_Number}
                </p>
              </Modal.Body>
            </Modal>

            {/* ********** */}

            <Modal
              show={this.state.shareLink}
              centered
              onHide={() => this.setState({ shareLink: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title>Share Link</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FacebookShareButton url={`partycrack.com${this.props.match.url}`}>
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>{" "}
                <WhatsappShareButton url={`partycrack.com${this.props.match.url}`}>
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <div>
                  <CopyToClipboard
                    text={`partycrack.com${this.props.match.url}`}
                    onCopy={() => this.setState({ copied: true })}
                  >
                    <button className="copyToClipBord">
                      Copy to clipboard
                    </button>
                  </CopyToClipboard>
                  {this.state.copied ? (
                    <span style={{ color: "red" }}>Copied.</span>
                  ) : null}
                </div>
              </Modal.Body>
            </Modal>

            {/* ********** */}
            <Footer />
          </>
        ) : (
          <div className="spinner">
            <Spinner animation="border" variant="danger" />
          </div>
        )}
         {isOpen ? (
          <Lightbox
          onCloseRequest="yes"
          enableZoom={false}
            mainSrc={this.state.PhotosLink[photoIndex]}
            nextSrc={this.state.PhotosLink[(photoIndex + 1) % this.state.PhotosLink.length]}
            prevSrc={this.state.PhotosLink[(photoIndex + this.state.PhotosLink.length - 1) % this.state.PhotosLink.length]}
            onCloseRequest={() =>{document.body.classList.remove('hidden-sidebar');this.setState({ isOpen: false })}}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + this.state.PhotosLink.length - 1) % this.state.PhotosLink.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.state.PhotosLink.length,
              })
            }
          />
        ):""}
       {isOpenMenu ? (
          <Lightbox
          onCloseRequest="yes"
          enableZoom={false}
            mainSrc={this.state.PhotosLinkMenu[photoIndexMenu]}
            nextSrc={this.state.PhotosLinkMenu[(photoIndexMenu + 1) % this.state.PhotosLinkMenu.length]}
            prevSrc={this.state.PhotosLinkMenu[(photoIndexMenu + this.state.PhotosLinkMenu.length - 1) % this.state.PhotosLinkMenu.length]}
            onCloseRequest={() =>{document.body.classList.remove('hidden-sidebar');this.setState({ isOpenMenu: false })}}
            onMovePrevRequest={() =>
              this.setState({
                photoIndexMenu: (photoIndexMenu + this.state.PhotosLinkMenu.length - 1) % this.state.PhotosLinkMenu.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndexMenu: (photoIndexMenu + 1) % this.state.PhotosLinkMenu.length,
              })
            }
          />
        ):""}
      </>
    );
  }
}


export default withRouter(venues_details);