import React from 'react';
import {Link} from 'react-router-dom'
import { RiUser3Line, } from 'react-icons/ri';
import { SiFacebook } from 'react-icons/si';
import { AiOutlineTwitter,AiOutlineMenu,AiFillInstagram,AiFillCaretDown } from 'react-icons/ai';
 import { FaLinkedin,FaUserPlus,FaGooglePlus,FaRegUser } from 'react-icons/fa';
 import { FiChevronDown,FiPlus } from 'react-icons/fi';
 import { ImCross } from 'react-icons/im';
import { Nav,Navbar,NavDropdown,Modal } from 'react-bootstrap';
import LogoImg from '../../assets/img/logo3.png'
import Manues from './Manue'
// manue imges
import SideNav from './SideNav'


  class TopHeader extends React.Component {
      constructor(props) {
    super(props);
    this.state = { cityShow:false,show: false, scrolled: false,sideNav: false, activeManue :null };
  }

  handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  toggalNav=()=>{
    this.setState({sideNav:!this.state.sideNav})
  }
  toggalManues=(e)=>{
    if(e==this.state.activeManue){
       this.setState({activeManue: null})
    }else{
      this.setState({activeManue:e})
    }
    
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  logout=()=>{
    localStorage.removeItem("user_token")
    this.props.logout()
    
  }

    render() {
    return (
        <>
 <header className="main-header">
      <div className="top-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-4">
              <ul className="social-list clearfix text-left py-2" style={{float:'none'}}>
                <li><a href="https://www.facebook.com/PartyCrack-101491208588361" target='_blank' className="facebook-bg"><SiFacebook/></a></li>
                <li><a href="https://www.instagram.com/partycrack2021/" target='_blank' className="twitter-bg"><AiFillInstagram/></a></li>
{/*                 <li><a href="#" className="google-bg"><FaGooglePlus/></a></li>
 */}                <li><a href="https://www.linkedin.com/in/partycrack" target='_blank' className="linkedin-bg"><FaLinkedin/></a></li>
              </ul> 
            </div>
            <div className="col-8 text-right">
              <Link className="bescom_vendor d-inlineBlock" to="/vendor-signup">Register as vendor</Link>
            </div>
          </div>
        </div>
      </div>
      <div 
        className={`sticky-header-fluid ${this.state.scrolled?"header-shrink":""}`}>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light px-0" >
            <button className="navbar-toggler mr-2 p-0 border-0 pink-color" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            onClick={this.toggalNav}
            aria-expanded="false" aria-label="Toggle navigation">
            <AiOutlineMenu/>
          </button>
          <Link exat to="/" className="navbar-brand logo">  <p className="font-24 mb-0 pink-color font-weight-bold"><img src={LogoImg} alt="logo" className="logo_image_main" /></p></Link>
        
          <div className={`navbar-collapse collapse ${this.state.sideNav ? "menu-show  show" :""}`} id="navbarSupportedContent">
           <Manues/>
            <ul className="navbar-nav ml-auto d-none d-lg-flex">
              <li className="nav-item login-nav">
              <div className="align_menus_header">
                {this.props.login ?
              <a style={{cursor:"pointer"}} onClick={this.logout} className='nav-link'><FaRegUser/> Logout</a>
                 : 
                 <>
                 <Link  to="/user-signin" className='nav-link'><FaRegUser/> Login</Link>
                 <Link  to="/user-signup" className='nav-link'> / Register</Link> 
                 </>
                 }
 
                  </div>  
              </li>
            
            </ul>
          <SideNav 
            toggalNav={this.toggalNav}
            toggalManues={this.toggalManues}
             activeManue={this.state.activeManue}
             login={this.props.login}
             logout={this.props.logout}
             />
          </div>
          <ul className="rightnavContainer d-flex ml-auto d-lg-none">
            {/* <li className="select_cities mr-3">
              <a onClick={() => this.setState({ cityShow: true })} className="text-theme" href="#" data-toggle="modal" data-target="#citymodal">All Cities 
             <FiChevronDown/></a>
              </li> */}
              {/* <li className="login-mobile">
                <a className="text-theme" href="user_signin"><FaUserPlus/></a>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </header>
     <Modal
          show={this.state.cityShow}
          onHide={() => this.setState({ cityShow: false })}
          size="xl"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header
            closeButton
            style={{ background: "#ff4068", color: "white" }}
          >
            <Modal.Title id="example-custom-modal-styling-title">
              Select city
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <div
              className="HeaderDropDownContainer"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Top Cities</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">Sydney</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Melbourne
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Brisbane
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Perth</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Adelaide
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Canberra
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Hobart</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Darwin</NavDropdown.Item>
                 
                </div>
              </div>
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Popular Cities</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.2">Albany</NavDropdown.Item>
                  
                  <NavDropdown.Item href="#action/3.3">Albury</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Ballarat
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Bathurst
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Bendigo
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Blue Mountains
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Bunbury
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Bundaberg
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Sunshine Coast
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Tamworth
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Toowoomba
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Townsville
                  </NavDropdown.Item>
                 
                </div>
              </div>
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Other Cities</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.2">Cairns</NavDropdown.Item>
                  
                  <NavDropdown.Item href="#action/3.3">
                    Central Coast
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Coffs Harbour
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Dubbo</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Gawler</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Geelong
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Geraldton
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Gladstone
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Gold Coast
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Hervey Bay
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Kalgoorlie
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Wagga Wagga
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Warrnambool
                  </NavDropdown.Item>
                 
                </div>
              </div>
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Other Cities</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">
                    Launceston
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Lismore
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Wollongong
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Mackay</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Maitland
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Melton</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Mildura
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Newcastle
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Nowra</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Orange</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Port Macquarie
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Rockhampton
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Shepparton
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Sunbury
                  </NavDropdown.Item>
                
                </div>
              </div>
            </div>
          </Modal.Body>
       </Modal>
     
        </>
  )
} 
} 
export default TopHeader ;
