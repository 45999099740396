import React from "react";
import Slider from "react-slick";

import { AiOutlineHome, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";

import backLogo from "../../assets/imgs/black-logo.png";
// card imges`
import cardImge1 from "../../assets/img/Popular-Party-Crack-Searches/DJ-268x250.png";
import cardImge2 from "../../assets/img/Popular-Party-Crack-Searches/CateringServices-268x250.png";
import cardImge3 from "../../assets/img/Popular-Party-Crack-Searches/Cake-Cupcakes-268x250.jpg";
import cardImge4 from "../../assets/img/Popular-Party-Crack-Searches/Photography-268x250.png";
import { Link } from "react-router-dom";

export default function PapulerSection() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    prevArrow: <AiOutlineLeft />,
    nextArrow: <AiOutlineRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 995,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container-md container-fluid">
      <div className="row">
        <div className="col-12">
          <div class="vanueSearches text-center mt-5">
            <h1>Popular PartyCrack  Searches</h1>
            <img src={backLogo} alt="" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Slider className="slick-sliderContainer" {...settings}>
            <div>
              <div className="card-Container bg-white shadow">
                <div>
                  <img src={cardImge1} alt="" />
                </div>
                <div class="content">
                  <h3>
                    {" "}
                    <Link to={`/vendors_list/Music and Dance/DJs/${localStorage.getItem("city")?localStorage.getItem("city"):""}`}>DJs</Link>
                  </h3>
                </div>
              </div>
            </div>
            <div>
              <div className="card-Container bg-white shadow">
                <div>
                  <img src={cardImge2} alt="" />
                </div>
                <div class="content">
                  <h3>
                    {" "}
                    <Link to={`/vendors_list/Food/Catering Services/${localStorage.getItem("city")?localStorage.getItem("city"):""}`}>Catering Services</Link>
                  </h3>
                </div>
              </div>
            </div>
            <div>
              <div className="card-Container bg-white shadow">
                <div>
                  <img src={cardImge3} alt="" />
                </div>
                <div class="content">
                  <h3>
                    <Link to={`vendors_list/Food/Cake_CupCakes/${localStorage.getItem("city")?localStorage.getItem("city"):""}`}>Cake/CupCakes</Link>
                  </h3>
                </div>
              </div>
            </div>
            <div>
              <div className="card-Container bg-white shadow">
                <div>
                  <img src={cardImge4} alt="" />
                </div>
                <div class="content">
                  <h3>
                    <Link to={`vendors_list/Photographers/Photographers/${localStorage.getItem("city")?localStorage.getItem("city"):""}`}>Photographers</Link>
                  </h3>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
