import React from 'react';
import TopHeader from "../../Components/topHeader";
import SecondSlider from './SeconSlider';
import TopSlider from "./TopSlider";
import WhyChose from "./WhyChose";
import PapulerSection from "./PapulerSection";
import PartyVendor from "./PartyVendor";
import VedorVedio from "./VedorVedio";
import Footer from '../../Components/footer/footer'
import axios from "axios"

class Home extends React.Component {
    state={
      loginbtn:false,
      city:"",
      login:true
    }  

componentDidMount(){
  window.scrollTo(0, 0)
 if(localStorage.getItem("city")){
  localStorage.removeItem("allCities")
  this.setState({
    city:localStorage.getItem("city")
  })
 }
 else{
  localStorage.setItem("allCities",true)
  this.setState({
    city:"All Cities"
  })
}
     if(localStorage.getItem("user_token")){
     this.getList()
     }
     this.checkLogin()
   }
   checkLogin=()=>{
      let user = localStorage.getItem("user_token")
      if(!user){
       this.setState({
         login:false
       })
      }else{
        this.setState({
          login:true
        })
      }
  } 
 getCity=(city)=>{
  this.setState({
    city:city
  })
 }
 getList = async () => {
  let usertoken=localStorage.getItem("user_token")
  let { cat, subcat } = this.props.match.params;
  await axios.get(`${process.env.REACT_APP_API}/api/auth/wishlist/${this.state.pageNumber+1}`,{
    headers: {
        Authorization: `Bearer ${usertoken}`
    }
}).then(res => {
    if (res.data.Error === false) {
        this.setState({
          list:res.data.Data,
          likeicon:true,
          loading:false,
          pageCount:res.data.total_pages,
          totallists:res.data.total
        })
    }
    else if(res.data.token==false){
      localStorage.removeItem("user_token")
       this.props.history.push("/")
}
})
};
 logout=()=>{
   
   this.setState({
     login:false
   })
 }
 render() {
        return (
          <>
            <TopHeader login={this.state.login} logout={this.logout} getCity={(a)=>this.getCity(a)} city={this.state.city} />
            <TopSlider getCity={(a)=>this.getCity(a)} city={this.state.city} />
            {/* <SecondSlider/> */}
            <WhyChose />
            <PapulerSection />
            <PartyVendor />
           
            <VedorVedio />

            <Footer />
          </>
        );
    }
}

export default Home;