import React from "react";

import {FaHandshake} from 'react-icons/fa'
import BackGroundImge from "../../assets/img/about-us.jpg";
import {Link} from 'react-router-dom'

import {SiGraphql} from 'react-icons/si'
import {BiWorld} from 'react-icons/bi'
import {VscPreview} from 'react-icons/vsc'

import backLogo from "../../assets/imgs/black-logo.png";
export default function WhyChose() {




  return (

    <div className="main_why_choose_main">
      <div class="vanueSearches text-center mt-5">
            <h1>Why Choose Us</h1>
            <img src={backLogo} alt="" />
          </div>
    <div
      className="container-fluid homeImgeContainer"
      style={{ backgroundImage: `url(${BackGroundImge})` }}
    >
      <div className="container">
      
        <div className="row">
          
          <div class="col-lg-12">
            <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="service-info-2">
                  {/* <div class="number">3</div> */}
                  <div class="icon">
                    <BiWorld />
                  </div>
                  <div class="detail">
                    <h3>Everything in One Place</h3>
                    <p>
                    From Venues to Vendor you will find all at one place.
                    </p>
                  </div>
                </div>
              </div>
              
              <div class="col-lg-6 col-md-6">
                <div class="service-info-2">
                  {/* <div class="number">2</div> */}
                  <div class="icon">
                    <FaHandshake />
                  </div>
                  <div class="detail">
                    <h3>Zero Commission</h3>
                    <p>
                    No commission on our platform! That means more money in your pocket.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="service-info-2">
                  {/* <div class="number">1</div> */}
                  <div class="icon">
                    <SiGraphql />
                  </div>
                  <div class="detail">
                    <h3>The Best Algorithm</h3>
                    <p>
                    Our cutting-edge match algorithm gets you in touch with right people.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="service-info-2">
                  {/* <div class="number">4</div> */}
                  <div class="icon">
                    <VscPreview />
                  </div>
                  <div class="detail">
                    <h3>Review System</h3>
                    <p>
                      Our review system helps you select your vendor quickly and gets you the best service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
