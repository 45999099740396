import React from 'react';
import Footer from "../../../Components/footer/footer"
import Header from "../../../Components/topHeader/indexCitys"
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ReCAPTCHA from "react-google-recaptcha";
import BtnLoader  from '../../../Components/Loaders/WhiteLoaderbtn'

const validateForm = errors => {
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (valid = false));
    return valid;
};

class Usersignin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            apiLoading:false,
            email: null,
            password: null,
            errMsg:"",
            errors: {
                email: '',
                password: ''
            },
            specField: false,
            loginbtn:false,
            captcha:false,
            captchaErr:false,
            invalidCount:0,
            captchaShow:false
        };

    }
    isEmail=(val)=> {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(regEmail.test(val)){
          return true
        }else{
            return false
        }
    }
    componentDidMount() {
        let token=localStorage.getItem("user_token")
        if(token){
         this.props.history.push("/")
        } 

    }
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'email':
                errors.email =
                      this.isEmail(value)
                        ? ''
                        : 'Email must be valid!';
                break;
            case 'password':
                errors.password =
                    value.length < 8
                        ? 'Password must be at least 8 characters long!'
                        : '';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value });
    }

    handleSubmit = async (event) => {
        event.preventDefault()
         console.log(this.state)
        let {email,password,captcha}=this.state
        this.setState({
            specField: false,
            userExistErr:false,
            errMsg:"",
            captchaErr:false
           })

        let data = {
            email: this.state.email,
            password: this.state.password
        }

      if(!email || !password){
       this.setState({
        specField: true,
       })
      }
      
      else if( (this.state.invalidCount>3)  && (captcha==false)){
        this.setState({
            captchaErr:true,
            invalidCount:0
          })
      }
      else{
          
        if (validateForm(this.state.errors)) {
            this.setState({apiLoading:true})
            let _this = this
            await axios.post(`${process.env.REACT_APP_API}/api/auth/userlogin`, data).then(res => {
                if (res.data.status ==true) {
                    if (res.data.token) {
                        localStorage.setItem('user_token', res.data.token)
                        localStorage.setItem('user_name', res.data.user.name)
                        localStorage.setItem('user_email', res.data.user.email)
                        localStorage.setItem('user_id', res.data.user.id)                    
                    }
                    NotificationManager.success('Login Successfully', '', 800);
                    setTimeout(function () { _this.props.history.push('/') }, 1000);

                } else if(res.data.status ==false){
                    localStorage.setItem('user_id', res.data.user.id)
                    NotificationManager.success('OTP has Sent to your Email', '', 400);
                    setTimeout(function () { _this.props.history.push('/user-auth') }, 500);
 
                }
                else if(res.data.Error==true){
                    this.setState({
                        userExistErr:true,
                        errMsg:res.data.msg,
                        invalidCount:this.state.invalidCount+1
                    })
                }
                this.setState({apiLoading:false})

            }).catch(e => {
                console.log(e)
            }

            )
            
        }

          
      }
      if(this.state.invalidCount>3){
        this.setState({
            captchaShow:true,
        })
    } 
    }
    reCAPTCHA=(value)=>{
        console.log("Captcha value:", value);
    
        if(value){
        this.setState({
          captcha:true
        })  
        }else{
          this.setState({
            captcha:false
          })
        }
    
      }
    render() {
        let { errors,specField,userExistErr ,captchaErr} = this.state
        return (
            <>
                <Header />
                <div className="d-md-block d-none">

                    <div className="container-pic h-100 d-flex justify-content-center align-items-center">
                        <div className="area">
                             <h1 className="sign">Sign in</h1>
                            {/* <ul className="bread-crumb">
                                <li className="signa">Home Signin</li>

                            </ul> */}
                        </div>
                    </div>
                </div>
                <div className="container-fluid container-lg">
                    <div className="row">
                        <div className="col-12">
                            <div className="row form-row-wrapper my-md-5">
                                <div className="col-12 col-md-6">
                                    <div className='wrapper '>
                                        <div className='form-wrapper py-5'>
                                            <h3 style={{ textAlign: "center" }} className="fo"> User Log In</h3>
                                            
                                            {specField ? <p style={{ textAlign: "center", color: "red" }}>Please enter email and password</p> : ""}
                                            {userExistErr ? <p style={{ textAlign: "center", color: "red" }}>Invalid Email or Password</p> : ""}
                                            {captchaErr ? (
                                           <p style={{ textAlign: "center", color: "red" }}>
                                              Please Fill Captcha
                                               </p>
                                                    ) : (
                                                   ""
                                                 )}
                                            <div className="d-flex justify-content-center py-3">
                                                <form onSubmit={this.handleSubmit} className="login_width_form">
                                                    <div className="form-group">
                                                        <input type="email" value={this.state.email} name='email'
                                                            onChange={this.handleChange} className="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Email" noValidate />
                                                             {errors.email.length > 0 && 
                                                                        <span className='error'>{errors.email}</span>} 
                                                    </div>
                                                    <div className="form-group" style={{marginBottom:"10px"}}>
                                                        <input type="password" value={this.state.password}
                                                            name='password' onChange={this.handleChange}
                                                            className="form-control" id="exampleInputPassword1"
                                                            placeholder="Password" noValidate />
                                                        {errors.password.length > 0 &&
                                                            <span className='error'>{errors.password}</span>}
                                                    </div>
                                                    <Link to="/forgot-password/user" style={{color:"#ff4068",fontWeight:"500",fontSize:"13px",textDecoration:"none",marginLeft:"11px"}}>Forgot your password?</Link>
                                                    <p style={{
                                                        marginTop:"15px",
                                                        fontSize: "17px",
                                                        fontWeight: "500",
                                                        textAlign: "center",
                                                        color: "#535353"
                                                    }}>Have a business account? <Link to="/vendor-signin" style={{
                                                        fontSize: "17px",
                                                        fontWeight: "500",
                                                        color: "#ff4068"
                                                    }} className='d-block d-md-inline'>Sign in</Link></p>
                                                    <div className="captcha">
                                                   {
                                                       this.state.captchaShow &&(
                                                        <ReCAPTCHA
                                                    sitekey="6LcKRkwbAAAAAEd5l23q3gnGFMV907naNnwMhWRt"
                                                     onChange={this.reCAPTCHA}
                                                     />
                                                       )
                                                       
                                                   }
                                                    
                                                       </div>
                                                    {this.state.apiLoading ? <BtnLoader/> :( <button disabled={this.state.loginbtn} type="submit" className="submit py-2 w-100">Sign in</button>) }
                                                    
                                                   
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 px-0">
                                    <div
                                        className="imageWrapper d-flex align-items-center justify-content-center h-100">
                                        <div className="button-wrap">
                                            <a className="btn btn-lg">Welcome to Partycrack</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NotificationContainer />
                    </div>

                </div>
                <Footer />
            </>
        )
    }
}

export default withRouter(Usersignin)
