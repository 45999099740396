import React, { useState, useEffect } from "react";

import cardImge1 from "../../assets/imgs/img9.png";
import backLogo from "../../assets/imgs/black-logo.png";
import slashImge from "../../assets/imgs/title-img2.png";

import Imge1 from "../../assets/img/Vendor-Categories/Planning&Decor-250x120.png";
import Imge2 from "../../assets/img/Vendor-Categories/Fashion-250x120.png";
import Imge3 from "../../assets/img/Vendor-Categories/ceremony.png";
import Imge4 from "../../assets/img/Vendor-Categories/Entertainers-250x120.png";
import Imge5 from "../../assets/img/Vendor-Categories/Services-250x120.png";
import axios from "axios";

import {BsChevronUp,BsChevronDown} from 'react-icons/bs'

import { Link } from "react-router-dom";

export default function PartyVendor() {
  let[ViewCategory,setViewCategory]=useState(false)
  let[category,setCategory]=useState([])
  let[city,setCity]=useState("")
  let[subMenu,setSubMenu]=useState("")
  let[limitCat,setlimitCat]=useState(6)

  






  useEffect(async()=>{
    if(localStorage.getItem("city")){
      setCity(localStorage.getItem("city"))
    }
    await axios.get(`${process.env.REACT_APP_API}/api/auth/getcategories/1`).then(res => {
      if (res.data.Error == false) {
        if(res.data.data1){
          setCategory(
            res.data.data1,
          )
        }
      }
    })
     
  },[])
  return (
    <div
      className="container-fluid mt-5 pb-4"
      style={{ background: "#f9f9f8" }}
    >
      <div className="container-md container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="vanueSearches text-center mt-5">
              <h1>Vendor Categories</h1>
              <img src={backLogo} alt="" />
            </div>
          </div>
        </div>
        <div className="row weddingcatagorList">
           {category.map((data,index)=>{
                if(index<limitCat)
                     return(
                      <div className="col-md-6 weddingCatagerWrapper">
                      <div className="row weddingCatagerContainer">
                        <div className="col-7 ">
                          <div className="des">
                            <div>
                     <h4 style={{cursor:"pointer"}} onClick={()=>{subMenu ? setSubMenu("") : setSubMenu(data.Category)}}>{data.Category} {subMenu==data.Category ? <BsChevronUp />  :<BsChevronDown /> } </h4>
                             
                              <p>
                                {data.SubCategory.map((data1,i)=>{
                                     let a= data1.replace("/", "_");
                                     if(i<2){
                                    return(
                                      <Link to={`/vendors_list/${data.Category}/${a}/${localStorage.getItem("city")?localStorage.getItem("city"):""}`}>{data1}{i<1?",":""}</Link>
                                    )
                                  }
                                  
                                })}
                       
                                 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-5 px-0">
                          <div className="imgeContainer">
                            <img src={`${process.env.REACT_APP_API}/${data.Images}`} alt="service images" />
                          </div>
                        </div>
                      </div>
                      {(subMenu && subMenu==data.Category) ? 
                                           <div className="row">
                                           <div className="main_style">
                                               <div className="adjust_space">
                                                 {data.SubCategory.map((data1,i)=>{
                                                   let a= data1.replace("/", "_");
                                                     return(
                                                       <Link to={`/vendors_list/${data.Category}/${a}/${localStorage.getItem("city")?localStorage.getItem("city"):""}`}>
                                                       <div className="sub-category-title text-secondary h6 font-override margin-t-20 margin-b-10">{data1}</div>
                                                   </Link>
                                                       )
                                                 })}
                   
                                               </div>
                                           </div>
                                         </div>
                       : 
                       ""}
 
                    </div>

                     )
          })} 

          
        </div>
         
        <div className="row">
          <div className="col-12 d-flex justify-content-center mt-5">
            <a style={{cursor:"pointer"}} className="whybtn text-center" onClick={()=>{limitCat==6 ? setlimitCat(9):setlimitCat(6)}} >
             {limitCat==6 ? "View all categories" :"View less categories"} 
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
