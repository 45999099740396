import React from "react";
import TopHeader from "../../Components/topHeader";
import Footer from "../../Components/footer/footer";
import { AiOutlineSearch, AiFillHeart ,AiOutlineHeart } from "react-icons/ai";

import {BsFilter} from "react-icons/bs"

import sideImge from "../../assets/img/img-4.jpg";
import aboutImge1 from "../../assets/img/icons/arrow.png";
import aboutIme2 from "../../assets/img/icons/arrow.png";
import slider1 from "../../assets/img/vendors/water-165219_640.jpg";
import { FaWhatsapp, FaStar } from "react-icons/fa";
import { FiPhone, FiHeart, FiShare2 } from "react-icons/fi";
import axios from "axios";
import { Link,withRouter } from "react-router-dom";
import { Multiselect } from 'multiselect-react-dropdown';
import {GrLocation} from 'react-icons/gr'
import Select from "react-select";
import InputRange from "react-input-range";
import{Spinner} from "react-bootstrap";

import ReactStars from "react-rating-stars-component";
 import "react-input-range/lib/css/index.css";
 import Collapsible from 'react-collapsible';
import ReactPaginate from 'react-paginate';




let eventOptions = [
  { Option: 'Sunday', Value: 'Sunday' },
  { Option: 'Saturday', Value: 'Saturday' },
  { Option: 'Monday', Value: 'Monday' },
  { Option: 'Tuesday', Value: 'Tuesday' },
  { Option: 'Wednesday', Value: 'Wednesday' },
  { Option: 'Thursday', Value: 'Engagement' },
  { Option: 'Friday', Value: 'Friday' },
]
const Priceoptions = [
  { value: 'Per Person', label: 'Per Person' },
  { value: 'Hire Fee', label: 'Hire Fee' },
  { value: 'Starting From', label: 'Starting From' },
  { value: 'Weekend Surcharge', label: 'Weekend Surcharge' },
  { value: 'Per hour', label: 'Per hour' },
  { value: 'Per day', label: 'Per day' },
  { value: 'Per event', label: 'Per event' },
]
let event = [
  { label: 'Kids Birthday', value: 'Kids Birthday' },
  { label: 'Adult Birthday', value: 'Adult Birthday' },
  { label: 'Bucks Night', value: 'Bucks Night' },
  { label: 'Hens Night', value: 'Hens Night' },
  { label: 'Corporate function/event', value: 'Corporate function/event' },
  { label: 'Engagement', value: 'Engagement' },
  { label: 'Wedding', value: 'Wedding' },
  { label: 'Meeting', value: 'Meeting' },
  { label: 'Get together', value: 'Get together' },
  { label: 'Others', value: 'Others' },

]
const IndustryOptions = [
  { value: 'Less than 3', label: 'Less than 3 years' },
  { value: 'Between 3 and 5', label: 'Between 3 and 5 years' },
  { value: 'More than 5', label: 'More than 5 years' }
];
const cateringOptions = [
  { value: 'Vegan_Menu', label: 'Vegan menu' },
  { value: 'Gluten_Free_Menu', label: 'Gluten Free menu' },
  { value: 'Halal_Menu', label: 'Halal Menu' },
  { value: 'Vegetarian_Menu', label: 'Vegetarian menu' },
  { value: 'Kosher_Menu', label: 'Kosher menu' }

]
class Vendors_list extends React.Component {
  state={
    list:[],
    like:false,
    likeicon:false,
    par:null,
    login:false,
    city:"",
    RadiusRange: 50,
    rating: null,
    slectedPriceRange: {min:null,max:null},
    price_option:"",
    paymentTerms:null,
    eventType:"",
    industryExp:"",
    availability:[],
    catering:"",
    cateringData:{},
    takeaway:false,
    foodtruck:false,
    cat:"",
    subcat:"",
    saturday:false,
    sunday:false,
    monday:false,
    tuesday:false,
    wednesday:false,
    thursday:false,
    friday:false,
    Category:"",
    Sub_Category:"",
    cities:"",
    loading:true,
    resetAvail:true,
    ratingRender:true,
    pageCount:1,
    pageNumber:0,
    filterColap:false,
    totallists:0
    
  }

  handlePageClick=(pageNumber)=>{
    let _this=this
   let page=pageNumber.selected
   this.setState({
     pageNumber:page
   })
   setTimeout(()=>{_this.filter(true,"number") },500)
  }

  UpdateRadius = (value) => {
    this.setState({
      RadiusRange: value,
    });
  };
  getRating = (value) => {
    this.setState({
      rating: value,
    });
  };

  getCity=(city)=>{
   this.setState({
     city:city
   })
   if(!this.state.Category && !this.state.Sub_Category){
    this.props.history.push(`/vendors_list/${city}`)


   }else{
    let a= (this.state.Sub_Category).replace("/", "_");
    this.props.history.push(`/vendors_list/${this.state.Category}/${a}/${city}`)
   }

  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps.match.params.subcat)
    let {subcat,cat,city}  =nextProps.match.params
    
    if((subcat !== this.props.match.params.subcat) && (city !== this.props.match.params.city) ){
      let data={
        cat:nextProps.match.params.cat,
        subcat:nextProps.match.params.subcat,
        cities:nextProps.match.params.city,
      }
     this.getList(data)
    }
   else if (nextProps.match.params.subcat !== this.props.match.params.subcat) {
           let data={
             cat:nextProps.match.params.cat,
             subcat:nextProps.match.params.subcat,
             cities:nextProps.match.params.city
           }
           this.setState({par:data})
          this.getList(data)
    }   else if (nextProps.match.params.city !== this.props.match.params.city) {
      let data={
        cat:nextProps.match.params.cat,
        subcat:nextProps.match.params.subcat,
        cities:nextProps.match.params.city
      }
      this.setState({par:data})
     this.getList(data)
}else{
      let data={
        cat:this.props.match.params.cat,
        subcat:this.props.match.params.subcat,
        cities:this.props.match.params.city,
      }
      this.setState({par:data})
      this.getList(data)
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0)
    document.body.classList.remove('hidden-sidebar')
    if(window.screen.width>767){
        this.setState({
          filterColap:true
        })
    }
    if(localStorage.getItem("city")){
      this.setState({
        city:localStorage.getItem("city")
      })
    }
    
    let data={
      cat:this.props.match.params.cat,
      subcat:this.props.match.params.subcat,
      cities:this.props.match.params.city

    }
    this.getList(data);
    this.checkLogin()   
  }
  checkLogin=()=>{
    let user = localStorage.getItem("user_token")
    if(user){
     this.setState({
       login:true
     })
    }
} 

getList=async (dat)=>{
  if(window.screen.width>767){
    this.setState({
      filterColap:true
    })
}
  let {cat,subcat} =this.props.match.params
  let usertoken= localStorage.getItem("user_token")
  this.setState({par:dat})

  let a;
  if(dat.subcat!=undefined){
     a= dat.subcat.replace("_", "/");
  }
  this.setState({cat:dat.cat,subcat:a})

  let lat=""
  let lng=""
  let city=""
  if(localStorage.getItem("city")){
     lat=localStorage.getItem("lat");
     lng=localStorage.getItem("lng");
     city=dat.cities
  }
  this.setState({
    Category:dat.cat,
    Sub_Category:a,
    cities:city,
  })
  let payment=null
  if(this.state.paymentTerms){
    payment={Option:this.state.paymentTerms}
    }
   let data={
     Category:dat.cat,
     Sub_Category:a,
     cities:city,
     lat:lat,
     lng:lng,
     Distance:50,
     Price_min:this.state.slectedPriceRange.min,
     Price_max:this.state.slectedPriceRange.max,
     Payment_Terms:payment,
     Rating:this.state.rating,
     page:(this.state.pageNumber+1)
   }
   if(usertoken){
    await axios.post(`${process.env.REACT_APP_API}/api/auth/Favlistings`,data,{
      headers: {
          Authorization: `Bearer ${usertoken}`
      }
  }).then(res => {
      if (res.data.Error === false) {
          this.setState({
            list:res.data.data,
            likeicon:true,
            loading:false,
            pageCount:res.data.total_pages,
            totallists:res.data.total

          })
      }
      else if(res.data.token==false){
        localStorage.removeItem("user_token")
         this.props.history.push("/")
 }
  })

   }else{
    await axios.post(`${process.env.REACT_APP_API}/api/auth/activatedlistings`,data).then((res)=>{
      if(res.data.Error==false){
        this.setState({
          list:res.data.data,
          likeicon:false,
          loading:false,
          pageCount:res.data.total_pages,
          totallists:res.data.total


        }) 
      }
    })
   }
}

filter=async (cond,number)=>{
  let pagination=this.state.pageNumber
  console.log(number)
  if(number=="reset"){
    this.setState({
      pageNumber:0
    })
  pagination=0
  }
console.log(this.state.pageNumber)
  let{RadiusRange,rating,slectedPriceRange,price_option,paymentTerms,eventType,industryExp,catering,availability,foodtruck,takeaway} =this.state
  var availData=[]
  let saturday=false
  let sunday=false
  let monday=false
  let tuesday=false
  let wednesday=false
  let thursday=false
  let friday=false
  let usertoken=localStorage.getItem("user_token")
  let lat=""
  let lng=""
  let city=""
  if(localStorage.getItem("city")){
     lat=localStorage.getItem("lat");
     lng=localStorage.getItem("lng");
  }

availability.forEach((value,i)=>{
if(value.Option=="Saturday"){
    saturday=true
}
if(value.Option=="Sunday"){
 sunday=true
}
if(value.Option=="Monday"){  
    monday=true
}
if(value.Option=="Tuesday"){
    tuesday=true
}
if(value.Option=="Wednesday"){

    wednesday=true
 
}
if(value.Option=="Thursday"){
  thursday=true
}
if(value.Option=="Friday"){ 
    friday=true  
}
})
let Catering={}
if(this.state.catering){
 Catering= {
    [this.state.catering]: true,
  };
}
let payment=null
if(this.state.paymentTerms){
payment={Option:this.state.paymentTerms}
}

console.log("avail",this.state.cateringData)
let data={}
if(cond){
   data={
    Category:this.state.Category,
    Sub_Category:this.state.Sub_Category,
    cities:this.state.cities,
    lat:lat,
    lng:lng,
    Distance:50,
    Price_min:slectedPriceRange.min,
    Price_max:slectedPriceRange.max,
    Payment_Terms:payment,
    Rating:rating,
    page:pagination+1


  }
}else{
  data={
    Category:this.state.Category,
    Sub_Category:this.state.Sub_Category,
    Distance:RadiusRange,
    lat:lat,
    lng:lng,
    cities:this.state.city,
    Rating:rating,
    Price_min:slectedPriceRange.min,
    Price_max:slectedPriceRange.max,
    PriceOption:price_option,
    Payment_Terms:payment,
    Event_Type:eventType,
    Industry_Experience:industryExp,
    Service: {
      Options: {
          Takeaway_Only: takeaway,
          Food_Vehicle: foodtruck
      }
  },
    Saturday:saturday,
    Sunday:sunday,
    Monday:monday,
    Tuesday:tuesday,
    Wednesday:wednesday,
    Thursday:thursday,
    Friday:friday,
    Catering: Catering,
    page:pagination+1
    }
}

  if(usertoken){
    await axios.post(`${process.env.REACT_APP_API}/api/auth/Favlistings`,data,{
      headers: {
          Authorization: `Bearer ${usertoken}`
      }
  }).then(res => {
      if (res.data.Error === false) {
          this.setState({
            list:res.data.data,
            likeicon:true,
            pageCount:res.data.total_pages,
            totallists:res.data.total


          })
      }
      else if(res.data.token==false){
        localStorage.removeItem("user_token")
         this.props.history.push("/")
 }
  })

   }else{
    await axios.post(`${process.env.REACT_APP_API}/api/auth/activatedlistings`,data).then((res)=>{
      if(res.data.Error==false){
        this.setState({
          list:res.data.data,
          likeicon:false,
          pageCount:res.data.total_pages,
          totallists:res.data.total


        }) 
      }
    })
   }
console.log(data)
if(window.screen.width<767){
  this.setState({
    filterColap:false
  })
}
  
}

resetFilter=()=>{
  this.setState({
    RadiusRange:50,
    rating:null,
    slectedPriceRange:{min:null,max:null},
    price_option:"",
    paymentTerms:null,
    eventType:"",
    industryExp:"",
    catering:"",
    availability:[],
    foodtruck:false,
    takeaway:false,
    resetAvail:false,
    ratingRender:false,
  })
  console.log("null",this.state.paymentTerms)
setTimeout(()=>{  this.setState({
  resetAvail:true,
  ratingRender:true,
  pageNumber:1,


});this.filter(true,"reset")
},400)

}
getTotalReviews=()=>{
  console.log("Aa")
}

likeList=async(a,listid)=>{
  let{RadiusRange,rating,slectedPriceRange,price_option,paymentTerms,eventType,industryExp,catering,availability,foodtruck,takeaway} =this.state
 let id=localStorage.getItem("user_id")
  this.state.list.map((data)=>{
    if(data._id==listid){
      data.favourite=!data.favourite
    }
  })
  this.setState({
    like:!this.state.like
  })
  let data={
    id:id,
    listingid:listid
  }
  await axios.post(`${process.env.REACT_APP_API}/api/auth/addfavourite`,data).then((res)=>{
    if(res.data.Error==false){
      this.setState({
        like:a
      }) 
    }
  })
  

}

likeListLog=async()=>{
this.props.history.push("/user-signin")
}
logout=()=>{
  
  this.setState({
    login:false,
    likeicon:false
  })
}
  render() {
    console.log(this.state.slectedPriceRange)
    return (
      <>
      
        <TopHeader
          getCity={(a) => this.getCity(a)}
          city={this.state.city}
          logout={this.logout}
          login={this.state.login}
          resetFilter={this.resetFilter}
        />
        {
          this.state.loading ?
          <div className="spinner">
          <Spinner animation="border" variant="danger" />
          </div>
          :
          <>
           <div className=" d-md-block">
          <div className="container-pic h-100 d-flex justify-content-center align-items-center">
            <div className="area">
              <h1 className="sign">Search Vendors</h1>
              {/* <ul className="bread-crumb">
                <li className="signa">Home Vendors</li>
              </ul> */}
            </div>
          </div>
        </div>
        <br />

        {/* ****************** */}
        <div className="container-fluid">
          <div className="row py-md-3">
            <div className="col-md-6">
              {this.props.match.params.city ? (
                <h4>
                  {this.props.match.params.subcat ?  this.props.match.params.subcat.replace("_","/"):""} In{" "}
                  {this.props.match.params.city}
                </h4>
              ) : (
                <h4>{this.props.match.params.subcat ?  this.props.match.params.subcat.replace("_","/"):""}</h4>
              )}
              {this.state.list ? (
                <p>
                  Showing {this.state.totallists} results as per your search
                  criteria
                </p>
              ) : (
                <p>Showing 0 results as per your search criteria</p>
              )}
            </div>
          </div>
          <div className="row">
            <div class="col-md-3">
              {/* filters starts */}
              

              <div className="main_filter">
                <button onClick={()=>{this.setState({filterColap:!this.state.filterColap})}} className="filter_btn">Filter <BsFilter /></button>
              </div>
              {this.state.filterColap ? 
                          
              <div className="filter_container">
                {this.props.match.params.city ? (
                  <div className="filter_wraper">
                    <h3>Radius Around Location</h3>
                    <div class="s-border"></div>
                    <div class="m-border"></div>
                    <div className="filterList radiusRangePicker px-2">
                      <InputRange
                        maxValue={100}
                        minValue={0}
                        value={this.state.RadiusRange}
                        onChange={(value) =>
                          this.setState({ RadiusRange: value })
                        }
                        // onChangeComplete={(value) =>
                        //   alert(JSON.stringify(value, null, 2))
                        // }
                      />
                      <br />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="filter_wraper">
                  <h3>Rating</h3>
                  <div class="s-border"></div>
                  <div class="m-border"></div>
                  <div className="filterList radiusRangePicker mt-n3">
                  {this.state.ratingRender &&
                  <ReactStars
                  count={5}
                  half={true}
                  value={this.state.rating}
                  edit={true}
                  onChange={(ratingChanged) =>
                    this.getRating(ratingChanged)
                  }
                  size={24}
                  activeColor="#ff4068"
                  // isHalf={true}
                />
                  }  
                  </div>
                </div>
                <div className="filter_wraper">
                  <h3>Pricing Range</h3>
                  <div class="s-border"></div>
                  <div class="m-border"></div>
                  <Select
                    className="customSelect paymentTerms2"
                    // value={{ label: this.state.price_option }}
                    value={{ label: this.state.price_option }}
                    onChange={(data) => {
                      this.setState({ price_option: data.value });
                    }}
                    classNamePrefix="customSelectOpt"
                    options={Priceoptions}
                  />
                  <br />
                  <div className="filterList radiusRangePicker p-2">
                    <InputRange
                      draggableTrack
                      maxValue={10000}
                      minValue={0}
                      onChange={(value) =>
                        this.setState({ slectedPriceRange: value })
                      }
                      // onChangeComplete={(value) => console.log(value)}
                      value={this.state.slectedPriceRange}
                    />
                    <br></br>
                  </div>
                </div>
                <div className="filter_wraper">
                  <h3>Payment Terms</h3>
                  <div class="s-border"></div>
                  <div class="m-border"></div>
                  <Select
                    className="customSelect paymentTerms60 mt-n3"
                    classNamePrefix="customSelectOpt"
                    value={{ label: this.state.paymentTerms }}
                    onChange={(data) => {
                      this.setState({ paymentTerms: data.value });
                    }}
                    options={[
                      { value: "No Advance", label: "No Advance" },
                      { value: "Advance", label: "Advance" },
                      { value: "Full Advance", label: "Full Advance" },
                    ]}
                  />
                  <br />
                </div>
                <div className="filter_wraper mt-2">
                  <h3>Type Of Events</h3>
                  <div class="s-border"></div>
                  <div class="m-border"></div>
                  <Select
                    className="customSelect paymentTerms3 mt-n3"
                    // value={{ label: this.state.price_option }}
                    value={{ label: this.state.eventType }}
                    onChange={(data) => {
                      this.setState({ eventType: data.value });
                    }}
                    classNamePrefix="customSelectOpt"
                    options={event}
                  />
                  <br />
                </div>
                <div className="filter_wraper ">
                  <h3>Years of Industry Experience</h3>
                  <div class="s-border"></div>
                  <div class="m-border"></div>
                  <Select
                    className="customSelect paymentTerms12 mt-n3"
                    // value={{ label: this.state.price_option }}
                    value={{ label: this.state.industryExp }}
                    onChange={(data) => {
                      this.setState({ industryExp: data.value });
                    }}
                    classNamePrefix="customSelectOpt"
                    options={IndustryOptions}
                  />
                  <br />
                </div>
                {this.state.cat == "Food" &&
                this.state.subcat == "Catering Services" ? (
                  <>
                    <div className="filter_wraper paymentTerms2">
                      <div className="filterList radiusRangePicker mt-n3">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            value={this.state.takeaway}
                            checked={this.state.takeaway}
                            onChange={(e) => {
                              this.setState({ takeaway: !this.state.takeaway });
                            }}
                            className="custom-control-input"
                            id="facility5"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="facility5"
                          >
                            Takeaway only
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            value={this.state.foodtruck}
                            checked={this.state.foodtruck}
                            onChange={(e) => {
                              this.setState({
                                foodtruck: !this.state.foodtruck,
                              });
                            }}
                            className="custom-control-input"
                            id="f6"
                          />
                          <label className="custom-control-label" htmlFor="f6">
                            Food Truck/Car/Van{" "}
                          </label>
                        </div>
                      </div>
                      <br />
                    </div>
                    <div className="filter_wraper ">
                      <h3>Catering</h3>
                      <div class="s-border"></div>
                      <div class="m-border"></div>
                      <Select
                        className="customSelect paymentTerms6 mt-n3"
                        // value={{ label: this.state.price_option }}
                        value={{ label: this.state.catering }}
                        onChange={(data) => {
                          this.setState({
                            catering: data.value,
                            cateringData: { [data.value]: true },
                          });
                        }}
                        classNamePrefix="customSelectOpt"
                        options={cateringOptions}
                      />
                      <br />
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="filter_wraper ">
                  <h3>Availability</h3>
                  <div class="s-border"></div>
                  <div class="m-border"></div>
                  {this.state.resetAvail && (<Multiselect
                    className="customSelect paymentTerms6 mt-n3"
                      classNamePrefix="customSelectOpt"
                       options={eventOptions}
                      selectedValues={this.state.availability}
                      onSelect={(data) => {
                     this.setState({ availability: data });
                      }}
                  onRemove={(data) => {
                    console.log("ff",data)
                  this.setState({ availability: data });
                  }}
                 displayValue="Option"
                  />)
                }


                  <br />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <button className="viewfilter" onClick={this.resetFilter}>
                    Reset
                  </button>
                  <button
                    className="viewfilter"
                    onClick={() => this.filter(false,"reset")}
                  >
                    View Result
                  </button>

                  <br />
                </div>
              </div>
            :
            ""  
              }

  

              {/*  filters ends*/}
            </div>
            <div class="col-md-9">
              <div className="row">
                {this.state.list &&
                  this.state.list.map((data, i) => {
                    let a=data.Mobile_Number.substring(1)
                    return (
                      <div class="col-lg-4 col-md-6">
                        <div className="parebnt_list">
                        <Link to={`/list_details/${data._id}`}>
                        <div class="partycrack-box">
                          <div class="partycrack-thumbnail">
                            <a href="#0" class="partycrack-img">
                              <img
                                class="d-block w-100"
                                src={`${process.env.REACT_APP_API}/${data.Profile_Pic}`}
                                alt="partycrack"
                              />
                            </a>
                            
                          </div>
                          <div class="content_vendor">
                            <div class="d-md-flex justify-content-between">
                              <div class="detail">
                                <h1 class="title">
                                  <Link to={`/list_details/${data._id}`}>
                                    {data.Name}
                                  </Link>
                                </h1>
                                <div class="location align_reviews">
                                  <a >
                                    <i class="flaticon-pin"></i>
                                    <GrLocation/> {data.Address.City},{data.Address.State}
                                  </a>
                                  
                                </div>
                              </div>
                              <div class="reat_reviews">
                                {
                                  data.Rating == "0" 
                                  ?
                                  <span style={{background:"grey"}} className="StarRating center rating-5 regular d-flex justify-content-center align-items-center">
                                  <i class="fa fa-star margin-r-5"></i>
                                  <i class="fa fa-bars"></i>
                                </span>
                                  :
                                  <span className="StarRating center rating-5 regular d-flex justify-content-center align-items-center">
                                  <i class="fa fa-star margin-r-5"></i>
                                  {data.Rating}
                                </span>
                                }
                                
                                <p>{data.Reviews=="0" ? "No Review":`${data.Reviews} Reviews`}</p>                                {/*   <small>19 reviews</small> */}
                                {/*   <small>19 reviews</small> */}
                              </div>
                            </div>
                            <div class="footer d-md-flex justify-content-between align-items-center">
                              <span class="strike ">
                                <i class="far fa-dollar-sign"></i>{" "}
                                <span className="price_font">${data.Price[0].Value}</span> {data.Price[0].Option}
                              </span>
                              

                            </div>
                          </div>
                        </div>
                        </Link>
                        {this.state.likeicon ? (
                              <a class="favourites custom_favourite_style active">
                                {data.favourite ? (
                                  <AiFillHeart
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.likeList(false, data._id)
                                    }
                                  />
                                ) : (
                                  <AiOutlineHeart
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.likeList(true, data._id)
                                    }
                                  />
                                )}
                              </a>
                            ) : (
                              <a class="favourites custom_favourite_style active">
                              <AiOutlineHeart
                                    style={{ cursor: "pointer" }}
                                    onClick={
                                     this.likeListLog
                                    }
                                  />
                                  </a>
                            )}
                        {data.Accept_Whatsapp ?
                                   <a
                                     href={`https://wa.me/+61${a}`}
                                      class="whatsapp-contact d-flex justify-content-center align-items-center"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                           >
                                          <FaWhatsapp /> 
                                           {/* Contact */}
                                         </a>

                              : 
                              ""}
                            </div>

                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="row"></div>
          {this.state.list.length>0 ? 
                   <ReactPaginate
                   previousLabel={'<'}
                   nextLabel={'>'}
                   breakLabel={'...'}
                   breakClassName={'break-me'}
                   pageCount={this.state.pageCount}
                   marginPagesDisplayed={2}
                   pageRangeDisplayed={5}
                   forcePage			={this.state.pageNumber}
                   onPageChange={this.handlePageClick}
                   containerClassName={'pagination'}
                   activeClassName={'active'}
                 />
         
          :
          ""}
 
        </div>
          </>
        }
       
        {/* ****************** */}


        <Footer />
      </>
    );
  }
}

export default withRouter(Vendors_list);
