import React, { useState,useEffect } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios"
 import {
   FaLinkedin,
   FaUserPlus,
   FaGooglePlus,
   FaRegUser,
 } from "react-icons/fa";
 import { FiChevronDown, FiPlus, FiSearch } from "react-icons/fi";
 import { ImCross } from "react-icons/im";
  import manuImge1 from "../../assets/img/icon/Music&dance.png";
  import manuImge2 from "../../assets/img/icon/Food.png";
  import manuImge3 from "../../assets/img/icon/ProfessionalPhotographer.png";
  import manuImge4 from "../../assets/img/icon/Planning&Decor.png";
  import manuImge5 from "../../assets/img/icon/Fashion.png";
  import manuImge6 from "../../assets/img/icon/Invites&gift.png";
  import manuImge7 from "../../assets/img/icon/Ceremony.png";
  import manuImge8 from "../../assets/img/icon/Services.png";
  import manuImge9 from "../../assets/img/icon/Entertainers.png"; 
let SideNav = (props) => {
  const [category,setCategory]=useState([])
  useEffect(async()=>{
    await axios.get(`${process.env.REACT_APP_API}/api/auth/getcategories/1`).then(res => {
      if (res.data.Error == false) {
        if(res.data.data1){
          
           setCategory(res.data.data1)
          
        }
      }
    })
  },[])

  const logout=()=>{
  
      localStorage.removeItem("user_token")
     props.logout()
      
  
  }
  return (
    <ul className="navbar-nav ml-auto d-lg-none mobile_menu">
      <li className="nav-item login-nav position-relative">
         <div style={{padding:"12px 15px"}} className="align_menus_header">
         {props.login ?
                 <a style={{cursor:"pointer"}} onClick={logout} className='nav-link'><FaRegUser/> Logout</a>

                  :
                  <>
                  <Link  to="/user-signin" className='nav-link'><FaRegUser/> Login</Link>
                  <Link  to="/user-signup" className='nav-link'> / Register</Link>
                  </>   
         }
                  </div>

        <div className="closeNavContainer" onClick={props.toggalNav}>
          <ImCross />
        </div>
      </li>

      <li>
        <hr />
      </li>
      <li className="nav-item dropdown megamenu-li">
       {localStorage.getItem("user_token")?<Link className="dropdown-item" to="/wish_list">Wish List</Link>:""} 
      </li>
      <li className="nav-item">
        <h6 className="megamenu-title border-0 px-3 pb-0">All Partycrack</h6>
      </li>
      {category.map((data,index)=>{
       return(
        <li
        className="nav-item dropdown megamenu-li"
        onClick={() => props.toggalManues(index)}
      >
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src={`${process.env.REACT_APP_API}/${data.Icon}`} alt="" /> {data.Category}
        </a>
        <div
          className={`dropdown-menu ${
            props.activeManue == index ? "show" : ""
          }`}
          aria-labelledby="dropdown01"
        >
          {data.SubCategory.map((data1,i)=>{
  let a= data1.replace("/", "_");        
   return(
          <Link
          className="dropdown-item"
          onClick={props.toggalNav}
          to={`/vendors_list/${data.Category}/${a}/${localStorage.getItem("city") ?localStorage.getItem("city"):"" }`}
        >
          {data1}
        </Link>
         )
          })}

        </div>
      </li>
       )
      })}
    </ul>
  );
};
export default SideNav;
