import React, { useEffect,useState } from 'react';
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios"

let Menues = (props)=>{
  const [city, setCity] = useState("");
  const [category1, setCategory1] = useState([]);
  const [category2, setCategory2] = useState([]);



  useEffect(async() => {
    let id = localStorage.getItem("vendor_id")
   await axios.get(`${process.env.REACT_APP_API}/api/auth/getcategories/1`).then(res => {
      if (res.data.Error == false) {
        if(res.data.data1){
                  let a= res.data.data1.length
                  let b =Math.ceil(res.data.data1.length/2) 
                  let arr1=res.data.data1.splice(0,b);
                  let arr2=res.data.data1.splice(0,a-1);
                  setCategory1(arr1)
                  setCategory2(arr2)
        }
      }
    })

  },[]);
  
      return (
        <ul className="navbar-nav ml-auto d-none d-lg-flex">
          <li className="nav-item">
            <Link exat to="/" className="nav-link">
              Home
            </Link>
            {/* <a className="nav-link" href="/" id="">Home</a> */}
          </li>
          <li className="nav-item">
            <Link exat to="/about_us" className="nav-link">
              About
            </Link>
          </li>
{/*           <li className="nav-item dropdown megamenu-li">
            <NavDropdown
              className="HeaderDropDownContainer vaderlistContaienr"
              title="Venues"
              id="basic-nav-dropdown"
            >
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">By Type</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.2">
                    Restaurant
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Banquet hall
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Pub/Bar
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Play center
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Rooftop bar
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Boat/Cruise
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Farmhouse
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Wineries
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Nightclubs
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Ballrooms
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Wedding venues
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    {" "}
                    Motel/Hotel
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Conference/Meeting rooms
                  </NavDropdown.Item>
                   
                </div>
              </div>
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Top Cities</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">Sydney</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Melbourne
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Brisbane
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Perth</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Adelaide
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Canberra
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Hobart</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Darwin</NavDropdown.Item>
                </div>
              </div>
              <div></div>
            </NavDropdown>
          </li> */}
          <li className="nav-item dropdown megamenu-li">
            <NavDropdown
              className="HeaderDropDownContainer vaderlistContaienr"
              title="Vendors"
              id="basic-nav-dropdown"
            >
              <div>
              {
                  category1.length >0 && category1.map((data,index)=>{
                      return(
                        <>
                     <div className="dropDownSections">
                      <p className="dropDownHeading">{data.Category}</p>     
                       <NavDropdown.Divider />
                        {
                       data.SubCategory.map((data1,i)=>{
                           let a= data1.replace("/", "_");
                           return( <Nav.Link onClick={props.resetFilter} as={Link} to={`/vendors_list/${data.Category}/${a}/${localStorage.getItem("city") ? localStorage.getItem("city"):""}`}>
                         {data1}
                       </Nav.Link>
                           )
                         })
                       }  
     
                     </div>
                        </>
                         )
                      
                    

                  })
                }
              </div>
              <div>
              {
                  category2.length >0 && category2.map((data,index)=>{
                      return(
                        <>
                     <div className="dropDownSections">
                      <p className="dropDownHeading">{data.Category}</p>     
                       <NavDropdown.Divider />
                        {
                       data.SubCategory.map((data1,i)=>{
                           let a= data1.replace("/", "_");
                           return( <Nav.Link onClick={props.resetFilter} as={Link} to={`/vendors_list/${data.Category}/${a}/${localStorage.getItem("city") ? localStorage.getItem("city"):""}`}>
                         {data1}
                       </Nav.Link>
                           )
                         })
                       }  
     
                     </div>
                        </>
                         )
                      
                    

                  })
                }
              </div>

            </NavDropdown>
          </li>
          {props.userlogin ?           <li className="nav-item">
           <Link exat to="/wish_list" className="nav-link">Wish List</Link>            
          </li>
          :
          ""

          }
          <li className="nav-item">
            <Link exat to="/contact_us" className="nav-link">
              Contact Us
            </Link>
          </li>
        </ul>
      );
}
export default Menues;