import React , {useState} from "react";
import Slider from "react-slick";

import { AiOutlineHome, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { FaPlay } from "react-icons/fa";

import backLogo from "../../assets/imgs/black-logo.png";
// card imges`
import cardImge1 from "../../assets/imgs/img4.jpg";
import cardImge2 from "../../assets/imgs/img3.jpg";
import cardImge3 from "../../assets/imgs/img5.jpg";
import cardImge4 from "../../assets/imgs/img7.jpg";

import videos from '../../assets/videos/video1.mp4'
import video2 from '../../assets/videos/video2.mp4'
import thumbnail1 from '../../assets/videos/thumbnail1.png'


import ReactDOM from 'react-dom'
import ModalVideo from 'react-modal-video'

import '../../assets/css/modal-video.min.css'


export default function VedorVedio() {
  const [isOpen, setOpen] = useState(false)
  const [url, setUrl] = useState("")

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    prevArrow: <AiOutlineLeft />,
    nextArrow: <AiOutlineRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 995,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const Video=(data)=>{
    setUrl(data);
    setOpen(true)
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div class="vanueSearches text-center mt-5">
            <h1>Our Videos</h1>
           <img src={backLogo} alt="" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
        
          <ModalVideo channel='custom' autoplay='false' isOpen={isOpen} url={url} onClose={() => setOpen(false)} />

          <Slider className="slick-sliderContainer" {...settings}>
          <React.Fragment>
			      
            <div>
              <div className="card-Container">
                <div>
                  <img src={thumbnail1} alt="" />
                  <div className="btnconatiner2">
                    <div>
                      <a  onClick={()=>Video(videos)}>
                        <div>
                          <FaPlay />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
            <div>
              <div className="card-Container">
                <div>
                  <img src={thumbnail1} alt="" />
                  <div className="btnconatiner2">
                    <div>
                      <a onClick={()=>Video(video2)}>
                        <div>
                          <FaPlay />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="card-Container">
                <div>
                  <img src={thumbnail1} alt="" />
                  <div className="btnconatiner2">
                    <div>
                      <a onClick={()=>Video(video2)}>
                        <div>
                          <FaPlay />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
               <div className="card-Container">
                <div>
                  <img src={thumbnail1} alt="" />
                  <div className="btnconatiner2">
                    <div>
                      <a href="#0">
                        <div>
                          <FaPlay />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>{" "} 
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
