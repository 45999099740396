import React from "react";
import TopHeader from "../../Components/topHeader";
import Footer from "../../Components/footer/footer";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import {GrLocation} from 'react-icons/gr'
import sideImge from "../../assets/img/img-4.jpg";
import aboutImge1 from "../../assets/img/icons/arrow.png";
import aboutIme2 from "../../assets/img/icons/arrow.png";
import slider1 from "../../assets/img/vendors/water-165219_640.jpg";
import { FaWhatsapp, FaStar } from "react-icons/fa";
import{Spinner} from "react-bootstrap";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import ReactPaginate from 'react-paginate';
class WishList extends React.Component {
  state = {
    list: [],
    likeicon:false,
    login:false,
    loading:true,
    pageCount:1,
    pageNumber:0,
    totallists:0
  };

  getList=()=>{}
  handlePageClick=(pageNumber)=>{
    let page=pageNumber
    this.setState({
      pageNumber:page.selected
  })
  setTimeout(()=>{this.getList();},200)

   }
 
  componentDidMount() {
    window.scrollTo(0, 0)
    document.body.classList.remove('hidden-sidebar')
    let city=localStorage.getItem("city")
    this.setState({
      city:city
    })
    this.getList();
    this.checkLogin()
  }

  checkLogin=()=>{
    let user = localStorage.getItem("user_token")
    if(!user){
      this.props.history.push("/")
    }
    else{
      this.setState({
        login:true
      })
    }
} 
  getList = async () => {
    let usertoken=localStorage.getItem("user_token")
    let { cat, subcat } = this.props.match.params;
    await axios.get(`${process.env.REACT_APP_API}/api/auth/wishlist/${this.state.pageNumber+1}`,{
      headers: {
          Authorization: `Bearer ${usertoken}`
      }
  }).then(res => {
      if (res.data.Error === false) {
          this.setState({
            list:res.data.Data,
            likeicon:true,
            loading:false,
            pageCount:res.data.total_pages,
            totallists:res.data.total
          })
      }
      else if(res.data.token==false){
        localStorage.removeItem("user_token")
         this.props.history.push("/")
 }
  })
  };
  likeList=async(a,listid)=>{
    let id=localStorage.getItem("user_id")
     this.setState({
       like:!this.state.like,
     })
    let listData= this.state.list.filter((data)=>{
       if(data._id!=listid){
         return data
       }   
     })
     this.setState({
       list:listData
     })
     let data={
       id:id,
       listingid:listid
     }
     await axios.post(`${process.env.REACT_APP_API}/api/auth/addfavourite`,data).then((res)=>{
       if(res.data.Error==false){
         this.setState({
           like:a,
         }) 
       }
     })
     this.getList()
   }
 logout=()=>{
   this.setState({
     login:false
   })
   this.props.history.push("/")

 
 }
 getCity=(city)=>{
 this.setState({
   city:city
 })
}
 
  render() {
  
    return (
      <>
        <TopHeader getCity={(a)=>this.getCity(a)} city={this.state.city} logout={this.logout} login={this.state.login} />
        {this.state.loading ? 
                  <div className="spinner">
                  <Spinner animation="border" variant="danger" />
                  </div>
      :  
      <>
           <div className=" d-md-block">
          <div className="container-pic h-100 d-flex justify-content-center align-items-center">
            <div className="area">
              <h1 className="sign">Search WishList</h1>
              {/* <ul className="bread-crumb">
                <li className="signa">Home Vendors</li>
              </ul> */}
            </div>
          </div>
        </div>
        <br></br>
        <br></br>

        {/* ****************** */}
        <div className="container-fluid">
          <div className="row py-md-3">
            <div className="col-md-6">
              <h4>Wish List</h4>
              {this.state.list ? (
                <p>
                  Showing {this.state.totallists} results as per your search
                  criteria
                </p>
              ) : (
                <p>Showing 0 results as per your search criteria</p>
              )}
            </div>
          </div>
          <div className="row">
            {this.state.list &&
              this.state.list.map((data, i) => {
                let a=data.Mobile_Number.substring(1)
                console.log("num",a)
                return (
                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div className="parebnt_list">
                    <Link to={`/list_details/${data._id}`}>
                    <div  class="partycrack-box">
                      <div class="partycrack-thumbnail">
                        <a  class="partycrack-img">
                          <img
                            class="d-block w-100"
                            src={`${process.env.REACT_APP_API}/${data.Profile_Pic}`}
                            alt="partycrack"
                          />
                        </a>
                        

                      </div>
                      <div class="content_vendor">
                        <div class="d-md-flex justify-content-between">
                          <div class="detail">
                            <h1 class="title">
                              <Link to={`/list_details/${data._id}`}>
                                {data.Name}
                              </Link>
                            </h1>
                            <div class="location">
                              <a href="#0">
                                <i class="flaticon-pin"></i>
                                <GrLocation /> {data.Address.City} {data.Address.State} Australia
                              </a>
                            </div>
                          </div>
                          <div class="reat_reviews">
                          {
                                  data.Rating == "0" 
                                  ?
                                  <span style={{background:"grey"}} className="StarRating center rating-5 regular d-flex justify-content-center align-items-center">
                                  <i class="fa fa-star margin-r-5"></i>
                                  <i class="fa fa-bars"></i>
                                </span>
                                  :
                                  <span className="StarRating center rating-5 regular d-flex justify-content-center align-items-center">
                                  <i class="fa fa-star margin-r-5"></i>
                                  {data.Rating}
                                </span>
                                }
                                
                                <p>{data.Reviews=="0" ? "No Review":`${data.Reviews} Reviews`}</p>                                {/*   <small>19 reviews</small> */}
                              </div>
                        </div>
                        <div class="footer d-md-flex justify-content-between align-items-center">
                          <span class="strike ">
                            <i class="far fa-dollar-sign"></i>{" "}
                            ${data.Price[0].Value} {data.Price[0].Option}
                          </span>
                          
                        </div>
                      </div>
                      
                    </div>
                    </Link>
                    {data.Accept_Whatsapp ?             
                            <a
                             href={`https://wa.me/+61${a}`}
                             class="whatsapp-contact d-flex justify-content-center align-items-center"
                             target="_blank"
                             rel="noopener noreferrer"
                          >
                            <FaWhatsapp /> 
                          </a>
                          
                          : 
                          ""
                          }
                    </div>
                    <a class="favourites custom_favourite_style active" style={{ right:"26px" }}>

                        {this.state.likeicon ?
                              <AiFillHeart style={{cursor:"pointer"}} onClick={()=>this.likeList(false,data._id)} />
                                :
                              <AiOutlineHeart style={{cursor:"pointer"}} onClick={()=>this.likeList(true,data._id)} />
                                        
                      }
                                            </a>
                  </div>
                );
              })}
          </div>
          {this.state.list.length>0 ?
               <ReactPaginate
               previousLabel={'<'}
               nextLabel={'>'}
               breakLabel={'...'}
               breakClassName={'break-me'}
               pageCount={this.state.pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={5}
               onPageChange={this.handlePageClick}
               containerClassName={'pagination'}
               activeClassName={'active'}
             />
           :
           ""}
     
        </div>
      </>
      }

        {/* ****************** */}

        <Footer />
      </>
    );
  }
}

export default withRouter(WishList);
