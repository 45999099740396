import React from 'react';
let btnLoader =()=>{
      return (
        <div className='btnLoaders'>
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
}

export default btnLoader;