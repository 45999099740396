import React from 'react';
import {Link,withRouter} from 'react-router-dom'
import { RiUser3Line, } from 'react-icons/ri';
import { SiFacebook } from 'react-icons/si';
import { AiOutlineTwitter,AiOutlineMenu,AiFillInstagram,AiFillCaretDown } from 'react-icons/ai';
 import { FaLinkedin,FaUserPlus,FaGooglePlus,FaRegUser } from 'react-icons/fa';
 import { FiChevronDown,FiPlus,FiSearch } from 'react-icons/fi';
 import { ImCross } from 'react-icons/im';
import { Nav,Navbar,NavDropdown,Modal } from 'react-bootstrap';
import Manues from './Manue'
// manue imges
import LogoImg from '../../assets/img/logo3.png'
import axios from "axios"


import SideNav from './SideNav'
import { GiHeavyThornyTriskelion } from 'react-icons/gi';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
let coptions={
  type:['(regions)'],
  componentRestrictions:{country:"aus"}
}


  class TopHeader extends React.Component {
      constructor(props) {
    super(props);
    this.state = {
       cityShow:false,
       show: false, 
       venderModel:false,
       cities: "",
       searchcity:"",
       scrolled: false,sideNav: false, activeManue :null,
       login:false,
       vendor_address:"",
       lat:"",
       lng:"",
       city:"",
       adminArea:"",
       cityData:[],
       topCitiesData:[]
       };
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      cities:nextProps.city
    })
  }

  

  handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  toggalNav=()=>{
    if(!this.state.sideNav){
    document.body.classList.add('hidden-sidebar');
    }
    else{
      document.body.classList.remove('hidden-sidebar')
    }
     this.setState({sideNav:!this.state.sideNav})
  }
  toggalManues=(e)=>{
    if(e==this.state.activeManue){
       this.setState({activeManue: null})
    }else{
      this.setState({activeManue:e})
    }    
  }

  selectcities = (event) => {
    this.setState({ cities: event.target.value });
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      console.log("Enter key was pressed. Run your function.");
    }
    this.props.getCity(event.target.value)
  };
  selectAllCities=()=>{
    localStorage.removeItem("city")
    localStorage.removeItem("lat")
    localStorage.removeItem("lng")
    localStorage.setItem("allCities",true)

    this.setState({
        cityShow:false,
       cities:"" });
       if(this.props.getCity){
        this.props.getCity("All Cities")
       }
       if(this.props.resetFilter){
        this.props.resetFilter()
       }
       

   // this.props.history.push(`/vendors_list/${data}`)
  }
  handleSelect=(a)=>{
    let data=a.city.trim()
    localStorage.removeItem("allCities")
    localStorage.setItem("city",data)
    localStorage.setItem("lat",a.lat)
    localStorage.setItem("lng",a.lon)
    this.setState({
      cityShow:false,
       cities:data });
       if(this.props.getCity){
        this.props.getCity(data)

       }
       if(this.props.resetFilter){
        this.props.resetFilter()
       }
   // this.props.history.push(`/vendors_list/${data}`)
  }

  logout=()=>{
    localStorage.removeItem("user_token")
    this.props.logout()
    
  }

  _handleKeyDown =(e) => {
    if (e.key === 'Enter') {
      this.setState({ cityShow: false,});
      
    }
  }

  async componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    document.body.classList.remove('hidden-sidebar')
    await axios.get(`${process.env.REACT_APP_API}/api/auth/getcities`).then((res)=>{
     this.setState({
       cityData:res.data.Data.Cities,
       topCitiesData:res.data.Data.Top_Cities
     })
    })
    this.checkLogin()
  }

  checkLogin=()=>{
    let user = localStorage.getItem("user_token")
    if(user){
     this.setState({
       login:true
     })
    }
} 
handleChangeAddress = vendor_address => {
  this.setState({ vendor_address });
};

handleSelectAddress = (address, placeId) => {
  let _this=this
  this.setState({vendor_address:address})
  geocodeByAddress(address)
    .then(results =>{ 
      this.setState({ lat : results[0].geometry.location.lat()})
       localStorage.setItem("lat",results[0].geometry.location.lat())
         this.setState({ lng : results[0].geometry.location.lng()})
         localStorage.setItem("lng",results[0].geometry.location.lng())
      results[0].address_components.forEach(function(address){
        address.types.forEach(function(type) { 
          if(type ==='locality')
          localStorage.setItem("city",address.long_name)
          _this.setState({ city : address.long_name})
          if(type === "administrative_area_level_1")
          _this.setState({adminArea:address.long_name})
        })
        
        })
        this.props.getCity(localStorage.getItem("city"))
        localStorage.removeItem("allCities")
        this.setState({
          cityShow:false
        })
        if(this.props.resetFilter){
          this.props.resetFilter()
         }
      })
    .catch(error => console.error('Error', error));
};
    render() {
    return (
        <>
 <header className="main-header">
      <div className="top-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-4">
              <ul className="social-list align-items-center clearfix text-left py-2" style={{float:'none'}}>
                <li><a href="https://www.facebook.com/PartyCrack-101491208588361" target='_blank' className="facebook-bg"><SiFacebook/></a></li>
                <li><a href="https://www.instagram.com/partycrack2021/" target='_blank' className="twitter-bg"><AiFillInstagram/></a></li>
{/*                 <li><a href="#" className="google-bg"><FaGooglePlus/></a></li>
 */}                <li><a href="https://www.linkedin.com/in/partycrack" target='_blank' className="linkedin-bg"><FaLinkedin/></a></li>
              <li className='d-none d-md-block'>
    <div onClick={() => this.setState({ cityShow: true })} className='form-control '>{!this.state.cities ? "All Cities":this.state.cities} <AiFillCaretDown style={{ marginLeft: "12px" }}/></div>
             </li>
                            </ul>
            </div>

            <div className="col-md-6 col-8 text-right">
             {localStorage.getItem("user_token") ? "" : <Link className="bescom_vendor d-inlineBlock" to="/vendor-signup">Register as vendor</Link> } 
            </div>
          </div>
        </div>
      </div>
      <div 
        className={`sticky-header-fluid ${this.state.scrolled?"header-shrink":""}`}>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light px-0" >
            <button className="navbar-toggler mr-2 p-0 border-0 pink-color" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            onClick={this.toggalNav}
            aria-expanded="false" aria-label="Toggle navigation">
            <AiOutlineMenu/>
          </button>
            <Link exat to="/" className="navbar-brand logo">  <p className="font-24 mb-0 pink-color font-weight-bold"><img src={LogoImg} alt="logo" className="logo_image_main" /></p></Link>
        
          <div className={`navbar-collapse collapse ${this.state.sideNav ? "menu-show  show" :""}`} id="navbarSupportedContent">
           <Manues resetFilter={this.props.resetFilter} city={this.state.city}  userlogin={this.props.login}/>
            <ul className="navbar-nav ml-auto d-none d-lg-flex">
              <li className="nav-item login-nav">
                  {localStorage.getItem("user_token") ?
                 <a style={{cursor:"pointer"}} onClick={this.logout} className='nav-link'><FaRegUser/> Logout</a>

                  : 
                  <div className="align_menus_header">
                  <Link  to="/user-signin" className='nav-link'><FaRegUser/> Login</Link>
                  <Link  to="/user-signup" className='nav-link'> / Register</Link>   
                  </div>
                  }
              </li>
            
            </ul>
            <SideNav 
            toggalNav={this.toggalNav}
            toggalManues={this.toggalManues}
             activeManue={this.state.activeManue}
             login={this.props.login}
             logout={this.props.logout}
             />
          </div>
          <ul className="rightnavContainer d-flex ml-auto d-lg-none">
            <li className="select_cities mr-3">
              
              <a onClick={() => this.setState({ cityShow: true })} className="text-theme" href="#">{!this.state.cities ? "All Cities":this.state.cities} 
             <FiChevronDown/></a>
              </li>
              <li className="login-mobile">
                 {localStorage.getItem("user_token")?"":<Link className="text-theme"  to="/user-signin"><FaUserPlus/></Link>}  
           
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
     {/* <Modal
          show={this.state.cityShow}
          onHide={() => this.setState({ cityShow: false })}
          size="xl"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header
            closeButton
            style={{ background: "#ff4068", color: "white" }}
          >
            <Modal.Title id="example-custom-modal-styling-title">
              Select city
            </Modal.Title>
          </Modal.Header>
           <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="border modalSearchContainer">
                  <div>
                    <FiSearch />
                  </div>
                  <div>
                    <input placeholder="Search city..." type="text" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="HeaderDropDownContainer"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Top Cities</p>
                  <NavDropdown.Divider />
                  <div className="cityItems">
                    <NavDropdown.Item href="#action/3.1">
                      Sydney
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Melbourne
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Brisbane
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Perth
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Adelaide
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Canberra
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Hobart
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Darwin
                    </NavDropdown.Item>
                  </div>
                </div>
              </div>
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Popular Cities</p>
                  <NavDropdown.Divider />
                  <div className="cityItems">
                    <NavDropdown.Item href="#action/3.2">
                      Albany
                    </NavDropdown.Item>

                    <NavDropdown.Item href="#action/3.3">
                      Albury
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Ballarat
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Bathurst
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Bendigo
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Blue Mountains
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Bunbury
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Bundaberg
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Sunshine Coast
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Tamworth
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Toowoomba
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Townsville
                    </NavDropdown.Item>
                  </div>
                </div>
              </div>
              <div>
                <div className="dropDownSections pt-0 pt-md-5">
                  <div className="cityItems">
                    <NavDropdown.Item href="#action/3.2">
                      Cairns
                    </NavDropdown.Item>

                    <NavDropdown.Item href="#action/3.3">
                      Central Coast
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Coffs Harbour
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Dubbo
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Gawler
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Geelong
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Geraldton
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Gladstone
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Gold Coast
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Hervey Bay
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Kalgoorlie
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Wagga Wagga
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">
                      Warrnambool
                    </NavDropdown.Item>
                  </div>
                </div>
              </div>
              <div>
                <div className="dropDownSections pt-0 pt-md-5">
                  <div className="cityItems">
                  <NavDropdown.Item href="#action/3.1">
                    Launceston
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Lismore
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Wollongong
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Mackay</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Maitland
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Melton</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Mildura
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Newcastle
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Nowra</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Orange</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Port Macquarie
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Rockhampton
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Shepparton
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Sunbury
                  </NavDropdown.Item>
                </div>
                </div>
              </div>
            </div>
          </Modal.Body>
       </Modal> */}

<Modal
           show={this.state.cityShow}
          
           onHide={() => this.setState({ cityShow: false })}
          size="xl"
          aria-labelledby="example-modal-sizes-title-lg" className="full_width_modal"
        >
          <Modal.Header
            closeButton
            style={{ background: "#ff4068", color: "white" }}
          >
            <Modal.Title id="example-custom-modal-styling-title">
              Select city
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="border modalSearchContainer">
                  <div>
                    <FiSearch />
                  </div>
                  <div>
                    {/* <input 
                    onChange={this.selectcities} 
                    onKeyPress={this._handleKeyDown} 
                    placeholder="Search city..." 
                    type="text"  /> */}
                     <PlacesAutocomplete
                                  value={this.state.vendor_address}
                                  onChange={this.handleChangeAddress}
                                  onSelect={this.handleSelectAddress}
                                  searchOptions={coptions}
                                 //  searchOptions={searchOptions}
                              >
                                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                      <input
                                      style={{ boxShadow:"none" }}
                                        {...getInputProps({
                                          placeholder: 'Search city...',
                                          className: 'location-search-input form-control',
                                          
                                        })}
                                        onKeyUp={this._handleKeyDown}
                                      />
                                      <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                          const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                          return (
                                            <div
                                              {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                              })}
                                            >
                                              <div>{suggestion.description}</div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="HeaderDropDownContainer"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Top Cities</p>
                  <NavDropdown.Divider />
                  <div className="cityItems">
                  <NavDropdown.Item onClick={this.selectAllCities}>
                      All cities
                    </NavDropdown.Item>
                    {this.state.topCitiesData.map((data)=>{
                    return(
                      <NavDropdown.Item onClick={()=>this.handleSelect(data)}>
                      {data.city}
                    </NavDropdown.Item>
                    )                  
                    })}             
                  </div>
                </div>
              </div>
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Popular Cities</p>
                  <NavDropdown.Divider />
                  <div className="cityItems">
                    {this.state.cityData.map((data,index)=>{    
                      console.log(Math.ceil(index/3))                
                     if(index<=12){
                      return( <NavDropdown.Item onClick={()=>this.handleSelect(data)}>
                      {data.city}
                    </NavDropdown.Item>
                     )
                     }                                
                    })}
                    
                  </div>
                </div>
              </div>
              <div>
                <div className="dropDownSections pt-0 pt-md-5">
                  <div className="cityItems">
                  {this.state.cityData.map((data,index)=>{    
                      console.log(Math.ceil(index/3))                
                     if(index>13 && index<=26){
                      return( <NavDropdown.Item onClick={()=>this.handleSelect(data)}>
                      {data.city}
                    </NavDropdown.Item>
                     )
                     }                                
                    })}
                  </div>
                </div>
              </div>
              <div>
                <div className="dropDownSections pt-0 pt-md-5">
                  <div className="cityItems">
                  {this.state.cityData.map((data,index)=>{    
                      console.log(Math.ceil(index/3))                
                     if(index>26 && index<=this.state.cityData.length){
                      return( <NavDropdown.Item onClick={()=>this.handleSelect(data)}>
                      {data.city}
                    </NavDropdown.Item>
                     )
                     }                                
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
         <Modal
          show={this.state.venderModel}
          onHide={() => this.setState({ venderModel: false })}
          size="xl"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header
            closeButton
            style={{ background: "#ff4068", color: "white" }}
          >
            <Modal.Title id="example-custom-modal-styling-title">
              Select vendor
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="border modalSearchContainer">
                  <div>
                    <FiSearch />
                  </div>
                  <div>
                    <input
                      placeholder="Search vendor..."
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="HeaderDropDownContainer"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Invites & Gifts</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">
                    {" "}
                    Bomboniere/Favors
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">Gifts</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Invitation Cards
                  </NavDropdown.Item>
                </div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Fashion</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">
                    Bridesmaid Dresses
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Dress Designers
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Beauty Services
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Hair & Makeup
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Jewellery
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Wedding Dresses
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    {" "}
                    Character Wear
                  </NavDropdown.Item>
                </div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Ceremony</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">
                    Celebrants
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Priests
                  </NavDropdown.Item>
                </div>
              </div>
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Music and Dance</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">DJs</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Choreographer
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Wedding Entertainers/Music Bands
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Orchestra
                  </NavDropdown.Item>
                </div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Food</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">
                    Catering Services
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Cake/CupCakes
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Bartenders
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Barista
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Deserts
                  </NavDropdown.Item>
                </div>
              </div>
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Services</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">
                    Security
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Transport & Cars
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Party Furniture
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Party Supplies
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Cleaners
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    BabySitter
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Party helper
                  </NavDropdown.Item>
                </div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Entertainers</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">
                    Face Painters
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Swing/Games Provider
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Magicians
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Special Characters
                  </NavDropdown.Item>
                </div>
              </div>
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Photographers</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">
                    Photographers
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Cinema/Video
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Pre Wedding Shoot
                  </NavDropdown.Item>
                </div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Planning and Décor</p>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.1">
                    {" "}
                    Wedding Planners
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Event Planners
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Decorators
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Small Function Decors
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    Florist
                  </NavDropdown.Item>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    
     
        </>
  )
} 
} 
export default withRouter(TopHeader) ;
