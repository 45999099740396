import React from 'react';
import axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import BtnLoader from "../../Components/Loaders/WhiteLoaderbtn";
import ReCAPTCHA from "react-google-recaptcha";





const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};

class ContactFrom extends React.Component{
 state={
   name:"",
   email:"",
   subject:"",
   phone:"",
   message:"",
   errors: {
    email: '',
    phone:"",
},
PhoneNumberErr:"",
fieldsErr:false,
specField: false,
PhoneErr:false,
sendMsgbtn:false,
successMsg:false,
apiLoading: false, 
captcha:false,
captchaErr:false


 }

 handleChange = (event) => {
  event.preventDefault();
  const { name, value } = event.target;
  let errors = this.state.errors;
         
  switch (name) {
      case 'email':
          errors.email =
          this.isEmail(value)
                  ? ""
                  : 'Email must be valid!';
          break;
          case 'PhoneNumber':
              this.state.PhoneNumberErr =
                  value.length < 10
                      ? 'Phone Number must be at least 10 digits long!'
                      : '';
              break;
      default:
          break;
  }

  this.setState({ errors, [name]: value });
  this.setState({ [name]: value })
}

isEmail=(val)=> {
  let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(regEmail.test(val)){
    return true
  }else{
      return false
  }
}
phoneInput=(e)=>{
  const ch =String.fromCharCode(e.which)
if(!(/[0-9]/.test(ch))){
  e.preventDefault();
}
else if(this.state.phone.length>=11){
e.preventDefault()
}
}
  submitHandle= async(e)=>{
    e.preventDefault();
    let { name, email,phone,subject,message,captcha} = this.state
    this.setState({
        fieldsErr: false,
        specField: false,
        PhoneErr:false,
        captchaErr:false

    })

    let data = {
        name:name,
        mobile_number: phone,
        email: email,
        subject: subject,
        message:message
    }

    if (!email && !name && !phone && !subject && !message ) {
        this.setState({
            fieldsErr: true
        })
    }
    else if((this.state.phone.length>2) && (this.state.phone.length<10)){
        this.setState({
            PhoneErr: true
        })
    }
    else if (!email || !name || !phone || !subject || !message) {
        this.setState({
            specField: true
        })
    }
    else if(captcha==false){
      this.setState({
        captchaErr:true
      })
    }
    else {
        if (validateForm(this.state.errors)) {
          this.setState({
            apiLoading:true
          })
            let _this = this
            await axios.post(`${process.env.REACT_APP_API}/api/auth/addcontact`, data).then(res => {
              if (res.data.Error === false) {
                this.setState({
                  sendMsgbtn:true,
                  successMsg:true,
                  name:"",
                  email:"",
                  phone:"",
                  subject:"",
                  message:"",
                  apiLoading:false
                })
              } else {
               this.setState({
                sendMsgbtn:false,
                apiLoading:false
              })
              }
          })
        }
    }
  }
  reCAPTCHA=(value)=>{
    console.log("Captcha value:", value);

    if(value){
    this.setState({
      captcha:true
    })  
    }else{
      this.setState({
        captcha:false
      })
    }

  }
  render(){
    let {errors,fieldsErr,specField,PhoneErr,successMsg,captchaErr}=this.state
      return(
        <form onSubmit={this.submitHandle}>
          {fieldsErr ? <p style={{ textAlign: "center", color: "red" }}>Please Enter All Required* Fields</p> : ""}
          {specField ? <p style={{ textAlign: "center", color: "red" }}>Please Fill Empty Field</p> : ""}
           {PhoneErr ? <p style={{ textAlign: "center", color: "red" }}>Phone Number must be at least 10 digits long!</p> : ""}
           {successMsg ? <p style={{ textAlign: "center", color: "green" }}>Thank You For Contacting Us</p> : ""}
           {captchaErr ? (
                         <p style={{ textAlign: "center", color: "red" }}>
                          Please Fill Captcha
                          </p>
                             ) : (
                             ""
                        )}
           <div class="row">
              <div class="col-md-6">
                <div class="form-group name">
                  <input value={this.state.name} onChange={this.handleChange} type="text" name="name" class="form-control" placeholder="Name*"/>
                  {this.state.name.length > 0 ?
                                  ""
                                  :
                                  <span style={{ color: "red" }}>{this.state.errors.name}</span>}
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group email">
                  <input value={this.state.email} onChange={this.handleChange} type="email" name="email" class="form-control" placeholder="Email*"/>
                  {errors.email.length > 0 &&
                                        <span className='error'>{errors.email}</span>}
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group subject">
                  <input value={this.state.subject} onChange={this.handleChange}  type="text" name="subject" class="form-control" placeholder="Subject*"/>
                  {this.state.subject.length > 0 ?
                                  ""
                                  :
                                  <span style={{ color: "red" }}>{this.state.errors.subject}</span>}
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group number">
                  <input value={this.state.phone} onKeyPress={this.phoneInput} onChange={this.handleChange}  type="text" name="phone" class="form-control" placeholder="Number*"/>
                  {this.state.phone.length > 0 &&
                                       <span className='error'>{this.state.PhoneNumberErr}</span>} 
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group message">
                  <textarea value={this.state.message} onChange={this.handleChange}  class="form-control" name="message" placeholder="Write message*" style={{marginTop:"0px",marginBottom:"0px",height: "200px"}}></textarea>
                  {this.state.message.length > 0 ?
                                  ""
                                  :
                                  <span style={{ color: "red" }}>{this.state.errors.message}</span>}
                </div>
              </div>
                               <div className="col-md-12 captcha">
                                <ReCAPTCHA
                                sitekey="6LcKRkwbAAAAAEd5l23q3gnGFMV907naNnwMhWRt"
                                onChange={this.reCAPTCHA}
                                 />
                                 </div>
              <div class="col-md-12">
              {this.state.apiLoading ? (
                  <BtnLoader />
                ) : 
                <div class="send-btn text-center">
                  <button disabled={this.state.sendMsgbtn} type="submit" class="whybtn">Send Message</button>
                </div>
  }
              </div>
            </div>
            <NotificationContainer />
            </form>
      )
}
}

export default ContactFrom;