import React from "react";
import TopHeader from "../../Components/topHeader";
import Footer from "../../Components/footer/footer";
import {AiOutlineSearch} from 'react-icons/ai'
import { FaRegHandshake } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";

import sideImge from '../../assets/img/about-us.jpg'
import aboutImge1 from '../../assets/img/icons/arrow.png'
import aboutIme2 from "../../assets/img/icons/arrow.png";
class About extends React.Component {
  state={
    city:"",
    login:false
  }
  getCity=(city)=>{
    this.setState({
      city:city
    }) 
   }
   componentDidMount(){
    document.body.classList.remove('hidden-sidebar')
     this.setState({
       city:localStorage.getItem("city")
     })
    this.checkLogin()   
   }
   checkLogin=()=>{
    let user = localStorage.getItem("user_token")
    if(user){
     this.setState({
       login:true
     })
    }
}
logout=()=>{
  
  this.setState({
    login:false,
    likeicon:false
  })
}
  render() {
    return (
      <>
        <TopHeader
         getCity={(a) => this.getCity(a)}
         logout={this.logout}
         city={this.state.city}
         login={this.state.login}
        />
        <div className="d-md-block ">
          <div className="container-pic h-100 d-flex justify-content-center align-items-center">
            <div className="area">
              <h1 className="sign">About us</h1>
              {/* <ul className="bread-crumb">
                <li className="signa">Home > About</li>
              </ul> */}
            </div>
          </div>
        </div>

        <div class="about-real-estate bg-grea-3">
          <div class="container">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="about-info">
                  <img class="d-block w-100" src={sideImge} alt="Third slide" />
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 custom_collem_adjust">
                <div class="about-text">
                  <h3>Welcome to partycrack</h3>
                  <p>
                  We are a one-stop destination for all your party/event needs. You can search for anything related to your party needs and that too within your budget. From the list of venues, caterers, makeup artists, photographers we can help you with all. 
                  Why waste time searching for different vendors and venues when you can do all at one place, save time and enjoy more. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

          {/* <div class="how_works content-area-5">
            <div class="container">
              <div class="row">
                <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                  <div class="section-title text-center">
                    <h2 class="mb10">What Do We do?</h2>
                    <p>
                    Whether you need a large venue to accommodate your long list of guests or a vendor for a small private party at home, you can search, review and contact the perfect place and vendors for your parties from our long list of providers. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        <div class="how_works content-area-5 pt-4">
          <div class="container">
            <div class="row">
              <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                <div class="section-title text-center">
                  <h2 class="mb10">We strive for</h2>
                </div>
              </div>
            </div>
            <div class="row" id='aboutinfos'>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 text-center">
                <div class="feature">
                  <div class="feature-icon icon-circle circle-xxxl pinside80  bg-light-default text-default mr-auto ml-auto shape-circle rounded-0 mb40 ">
                    <AiOutlineSearch/>
                    <div class="process-number-second">
                      <div class="feature-icon icon-circle circle-lg bg-default text-white p-0 ">
                        1
                      </div>
                    </div>
                  </div>
                  <div class="feature-content p-4">
                    <h5>Easy to Use</h5>
                    <p>
                    Find the venue you love and send any questions or queries regarding it. 
                    Pick the event date and number of guests. After that, you can get in touch
                    with our venue manager directly, ask your questions, request a quote.
                    </p>
                  </div>
                  <div class="process-arrow d-none d-xl-block d-lg-block">
                    <img src={aboutIme2} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 text-center">
                <div class="feature">
                  <div class="feature-icon icon-circle  circle-xxxl pinside80  bg-light-default text-default mr-auto ml-auto shape-circle rounded-0 mb40  ">
                  <FaRegHandshake/>
                   <div class="process-number-second">
                      <div class="feature-icon icon-circle circle-lg bg-default text-white p-0 ">
                        2
                      </div>
                    </div>
                  </div>
                  <div class="feature-content p-4">
                    <h5>Budget-Friendly</h5>
                    <p>
                    If you are looking for vendors that are well within your budget, we are here for you! 
                    </p>
                  </div>
                  <div class="process-arrow d-none d-xl-block d-lg-block">
                    <img src={aboutImge1} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 text-center">
                <div class="feature">
                  <div class="feature-icon icon-circle circle-xxxl pinside80 mb40 bg-light-default text-default mr-auto ml-auto shape-circle rounded-0 ">
                   <FaStarHalfAlt/>
                   <div class="process-number-second">
                      <div class="feature-icon icon-circle circle-lg bg-default text-white p-0 ">
                        3
                      </div>
                    </div>
                  </div>
                  <div class="feature-content p-4">
                    <h5>Trustworthy and Reliable</h5>
                    <p>
                    We understand how important your party is for you. That's why you will only find relevant and verified vendors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <PartyCatagry/> */}

        <Footer />
      </>
    );
  }
}

export default About;
