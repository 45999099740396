import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Modal from 'react-bootstrap/Modal'
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
// imges
import { AiOutlineDown } from 'react-icons/ai';
import axios from 'axios';
import { FaSearch } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import imge1 from "../../assets/imgs/slider-party-01.jpg";
import imge2 from "../../assets/imgs/slider-party-11.jpg";
import imge3 from "../../assets/imgs/slider-party-09.jpg";
import imge4 from "../../assets/imgs/slider-party-10.jpg";
import imge6 from "../../assets/imgs/slider-party-08.jpg";
import imgeMobile from '../../assets/img/banner/img-11.jpg'
import mimg1 from "../../assets/img/mobile_banner/mslider1.jpg";
import mimg2 from "../../assets/img/mobile_banner/mslider2.jpg";
import mimg3 from "../../assets/img/mobile_banner/mslider3.jpg";
import mimg4 from "../../assets/img/mobile_banner/mslider4.jpg";
import mimg5 from "../../assets/img/mobile_banner/mslider5.jpg";

import { Link, withRouter } from 'react-router-dom';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
let coptions={
  type:['(regions)'],
  componentRestrictions:{country:"aus"}
}

class TopSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      venderModel: false,
      cities: "",
      searchcity:"",
      vendors:"",
      cat:"",
      subcat:"",
      newcat:"",
      newsubcat:"",
      newcities:"",
      vendor_address:"",
      lat:"",
      lng:"",
      city:"",
      adminArea:"",
      category:[],
      cityData:[],
       topCitiesData:[]

    };
  }
componentWillReceiveProps(nextProps){
  this.setState({
    cities:nextProps.city
  })
}
serachVendor=async(e)=>{
console.log('testing',e.target.value)
let data={sub_cat:e.target.value}
await axios.post(`${process.env.REACT_APP_API}/api/auth/searchedsubcategories`,data).then((res)=>{
  this.setState({
    category:res.data.Data
  })
})
}

  selectcities = (event) => {
    this.setState({ cities: event.target.value });
    console.log(this.state.cities)
    console.log(event.code)
    if (event.code === "Enter" || event.code === "NumpadEnter") {

      console.log("Enter key was pressed. Run your function.");

    }
   this.props.getCity(event.target.value)
  };

  selectvendortype = (event) => {
    this.setState({ subcat: event.target.value });
    console.log(event.code)
    if (event.code === "Enter" || event.code === "NumpadEnter") {

      console.log("Enter key was pressed. Run your function.");

    }

  };

  _handleKeyDownVendor =(e) => {
    if (e.key === 'Enter') {
      this.setState({ venderModel: false , subcat:e.target.value });
      
    }
  }

  _handleKeyDown =(e) => {
    console.log("ff",e)
    if (e.key === 'Enter') {
      this.setState({show:false  });
    }
  }
selectAllCities=()=>{
  localStorage.removeItem("city")
  localStorage.removeItem("lat")
  localStorage.removeItem("lng")
  localStorage.setItem("allCities",true)
  this.setState({show:false , cities:"" });  
  this.props.getCity("All Cities")
}
  handleSelect=(a)=>{
    let data=a.city.trim()
    localStorage.removeItem("allCities")
    localStorage.setItem("city",data)
    localStorage.setItem("lat",a.lat)
    localStorage.setItem("lng",a.lon)

    this.setState({show:false , cities:data });  
    this.props.getCity(data)

  }

  handleSelectVendor=(a)=>{
    console.log("aa",a)   
    this.setState({venderModel:false , cat:a.cat,subcat:a.subcat }); 
  }
  Search=(e)=>{
    let {cat,subcat,cities}= this.state
    let a= subcat.replace("/", "_");
    if(this.props.city && this.state.subcat ){
      this.props.history.push(`/vendors_list/${cat}/${a}/${this.props.city}`)
      // let data = { cat,subcat,cities }
      // axios.post(`${process.env.REACT_APP_API}/api/auth/searchvendorslistings`,data).then(res => {
      //   console.log(res.data)
      //   if (res.data.Error == false) {
      //     // if (res.data.data) {
      //     //   if (res.data.cat) {
      //     //     this.setState({ newcat: res.data.data.cat })
      //     //   }
      //     //   if (res.data.data.subcat) {
      //     //     this.setState({ newsubcat: res.data.data.subcat })
      //     //   }
      //     //   if (res.data.data.cities) {
      //     //     this.setState({ newcities: res.data.data.cities })
      //     //   }
      //     // }
      //     // let citiestoshow = []
      //     // let subcategorytoshow = []
      //     // let cattoshow = []
      //     // res.data.map((data, i) => {
      //     //   citiestoshow.push({ value: data.cities, label: data.City })
      //     //   subcategorytoshow.push(data.subcat)
      //     //   cattoshow.push(data.cat)
      //     // })
      //     // subCategory[0].map((data, i) => {
      //     //   subCategory2.push({ value: data, label: data })
      //     // })
      //     // this.setState({
      //     //   categories: categoryOptions,
      //     //   musicSubCat: res.data.data1,
      //     //   subCat: subCategory2
      //     // })
      //   }
      // })
    }
    else if(this.props.city){
      this.props.history.push(`/vendors_list/${this.props.city}`)
    }
    else if(this.state.subcat){
      this.props.history.push(`/vendors_list/${cat}/${a}`)
    }
    else if(localStorage.getItem("allCities")){
      this.props.history.push(`/vendors_list/All Cities`)
    }
    else if(!this.state.cities && !this.state.a){
      console.log("none")
    }
    
  }

  handleChangeAddress = vendor_address => {
    this.setState({ vendor_address });
  };

  handleSelectAddress = (address, placeId) => {
    let _this=this
    this.setState({vendor_address:address})
    geocodeByAddress(address)
      .then(results =>{ 
        this.setState({ lat : results[0].geometry.location.lat()})
         localStorage.setItem("lat",results[0].geometry.location.lat())
           this.setState({ lng : results[0].geometry.location.lng()})
           localStorage.setItem("lng",results[0].geometry.location.lng())
        results[0].address_components.forEach(function(address){
          address.types.forEach(function(type) { 
            if(type ==='locality')
            localStorage.setItem("city",address.long_name)
            _this.setState({ city : address.long_name})
            if(type === "administrative_area_level_1")
            _this.setState({adminArea:address.long_name})
          })
          
          })

          this.props.getCity(localStorage.getItem("city"))
           this.setState({
             show:false
           })
        
        })
      .catch(error => console.error('Error', error));
  };

async componentDidMount(){
  setTimeout(()=>{this.setState({cities:""})},400)
  await axios.get(`${process.env.REACT_APP_API}/api/auth/getcategories/1`).then(res => {
    if (res.data.Error == false) {
      if(res.data.data1){
        this.setState({
          category:res.data.data1,
          cities:""
        })
      }
    }
  })
  await axios.get(`${process.env.REACT_APP_API}/api/auth/getcities`).then((res)=>{
    this.setState({
      cityData:res.data.Data.Cities,
      topCitiesData:res.data.Data.Top_Cities
    })
   })
}
  render() {
        return (
      <>
        <div className="topHeaderCarasole">
          <Carousel className="laptop_slider">
            <Carousel.Item>
              <img className="d-block w-100" src={imge1} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={imge2} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={imgeMobile} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={imge3} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={imge4} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={imge6} alt="Second slide" />
            </Carousel.Item>
          </Carousel>
          <Carousel className="mobile_slider">
            <Carousel.Item>
              <img className="d-block w-100" src={mimg1} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={mimg2} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={mimg3} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={mimg4} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={mimg5} alt="Second slide" />
            </Carousel.Item>
          </Carousel>
          <div className="carasoleCenterContainer">
            <div>
              <h3>Find & Book your perfect Venues And Vendors</h3>
            </div>
            <p>
              Discover the biggest trove of farm houses, banquets, Photographers
              & more!
            </p>

            <div className="topSliderOptions">
              <div onClick={() => this.setState({ venderModel: true })}>
                <span>
                  {this.state.subcat !== ""
                    ? this.state.subcat
                    : "Select Vendor"}
                </span>
                <span>
                  <AiOutlineDown />
                </span>
              </div>
              <div onClick={() => this.setState({ show: true })}>
                <span>
                  {this.props.city !== "" ? this.props.city : "Select city"}
                </span>
                <span>
                  <AiOutlineDown />
                </span>
              </div>
              <div onClick={()=>{this.Search()}}>
                <span>
                  <FaSearch />
                </span>
                <span>
                  <button
                    style={{
                      cursor: "pointer",
                      outline: "none",
                      border: "none",
                      background: "#f34968",
                      color: "#fff",
                    }}
                  >
                    Search
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          size="xl"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header
            closeButton
            style={{ background: "#ff4068", color: "white" }}
          >
            <Modal.Title id="example-custom-modal-styling-title">
              Select city
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="border modalSearchContainer">
                  <div>
                    <FiSearch /> 
                  </div>
                  <div  >
{/*                     <input
                      onChange={this.selectcities}
                      onKeyPress={this._handleKeyDown}
                      placeholder="Search city..."
                      type="text"
                    /> */}
                              <PlacesAutocomplete
                                  value={this.state.vendor_address}
                                  onChange={this.handleChangeAddress}
                                  onSelect={this.handleSelectAddress}
                                  searchOptions={coptions}
                                 //  searchOptions={searchOptions}
                              >
                                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                      <input 
                                        style={{ boxShadow:"none" }}
                                        {...getInputProps({
                                          placeholder: 'Search city...',
                                          className: 'location-search-input form-control',
                                            
                                        })}
                                        onKeyUp={this._handleKeyDown}
                                      />
                                      <div className="autocomplete-dropdown-container" >
                                        {loading && <div >Loading...</div>}
                                        {suggestions.map(suggestion => {
                                          console.log("data ",suggestion)
                                          const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer',padding:"10px 20px" }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer',padding:"10px 20px" };
                                          return (
                                            <div 
                                              {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style, 
                                              })}
                                            >
                                              <span>{suggestion.description}</span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="HeaderDropDownContainer"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Top Cities</p>
                  <NavDropdown.Divider />
                  <div className="cityItems">
                  <NavDropdown.Item
                      onClick={this.selectAllCities}
                    >
                      All Cities
                    </NavDropdown.Item>
                    {this.state.topCitiesData.map((data)=>{
                    return(
                      <NavDropdown.Item onClick={()=>this.handleSelect(data)}>
                      {data.city}
                    </NavDropdown.Item>
                    )                  
                    })}
                  </div>
                </div>
              </div>
              <div>
                <div className="dropDownSections">
                  <p className="dropDownHeading">Popular Cities</p>
                  <NavDropdown.Divider />
                  <div className="cityItems">
                  {this.state.cityData.map((data,index)=>{    
                      console.log(Math.ceil(index/3))                
                     if(index<=12){
                      return( <NavDropdown.Item onClick={()=>this.handleSelect(data)}>
                      {data.city}
                    </NavDropdown.Item>
                     )
                     }                                
                    })}
                  </div>
                </div>
              </div>
              <div>
                <div className="dropDownSections pt-0 pt-md-5">
                  <div className="cityItems">
                  {this.state.cityData.map((data,index)=>{    
                      console.log(Math.ceil(index/3))                
                     if(index>13 && index<=26){
                      return( <NavDropdown.Item onClick={()=>this.handleSelect(data)}>
                      {data.city}
                    </NavDropdown.Item>
                     )
                     }                                
                    })}
                  </div>
                </div>
              </div>
              <div>
                <div className="dropDownSections pt-0 pt-md-5">
                  <div className="cityItems">
                  {this.state.cityData.map((data,index)=>{    
                      console.log(Math.ceil(index/3))                
                     if(index>26 && index<=this.state.cityData.length){
                      return( <NavDropdown.Item onClick={()=>this.handleSelect(data)}>
                      {data.city}
                    </NavDropdown.Item>
                     )
                     }                                
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.venderModel}
          onHide={() => this.setState({ venderModel: false })}
          size="xl"
          aria-labelledby="example-modal-sizes-title-lg" className="vendor_popup_main"
        >
          <Modal.Header
            closeButton
            style={{ background: "#ff4068", color: "white" }}
          >
            <Modal.Title id="example-custom-modal-styling-title">
              Select vendor
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="border modalSearchContainer">
                  <div>
                    <FiSearch />
                  </div>
                  <div>
                    <input
                      onChange={this.selectvendortype}
                      onKeyDown={this._handleKeyDownVendor}
                      placeholder="Search vendor..."
                      type="text"
                      onChange={this.serachVendor}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="HeaderDropDownContainer"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div  className="align_searh_links">
                {this.state.category.length>0 && this.state.category.map((data)=>{
                  return(
                    <div className="popopop">
                    <div className="dropDownSections">
                   
                    <p className="dropDownHeading">{data.Category}</p>
                   
                   
                   <NavDropdown.Divider />
                   {
                   this.state.category.length>0 && data.SubCategory.map((data1)=>{
                       return( 
                         <NavDropdown.Item
                         onClick={(a) =>
                           this.handleSelectVendor({
                             subcat: `${data1}`,
                             cat:`${data.Category}`,
                           })
                         }
                       >
                         {" "}
                         {data1}
                       </NavDropdown.Item>
                       )
                     })
                   } 
                   </div>
                 </div>
                  )
                })}

              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withRouter(TopSlider);
